import "./MarketSubscribeSection.scss";
import { useRef } from "react";
import NewsletterDialog from "./NewsletterSection/NewsletterDialog";
import { Link } from "react-router-dom";

const MarketSubscribeSection = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  return (
    <section className="market-partner-section">
      <div className="market-container">
        <div className="market-text-btn">
          <div className="market-title">
            <h1>Gain Your Market Edge Daily!</h1>
          </div>
          <div className="market-content">
            Stay on top of the game with real-time market updates, trend
            analysis, and tailored stock recommendations. Your ultimate tool for
            smart investing—delivered straight to your inbox, free of charge!
          </div>
          <div className="button">
            <button onClick={() => dialogRef.current?.showModal()}>
              Subscribe
            </button>
          </div>
        </div>
        <div className="market-img">
          <img
            src="assets/images/landingv2/market-img.png"
            alt="market-insights"
          />
        </div>
      </div>
      <div className="partner-section">
        <div className="main-title">Our Partner</div>
        <div className="partner-container">
          <div className="title-logo">
            <div className="logo">
              <svg
                width="60"
                height="60"
                viewBox="0 0 36 28"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 22H7V11H0V4h14v18zM28 22h-8l7.5-18h8L28 22z"
                  fill="currentColor"
                ></path>
                <circle cx="20" cy="8" r="4" fill="currentColor"></circle>
              </svg>
            </div>
            <div className="title">Trading View</div>
          </div>
          <div className="quotes-content">
            “AlgoAnalytics has partnered with TradingView for its charting
            technology. A global platform offering market data, heatmaps,{" "}
            <Link to="https://www.tradingview.com/screener/">
              stock screener
            </Link>{" "}
            and a{" "}
            <Link to="https://www.tradingview.com/economic-calendar/">
              real time economic calendar
            </Link>{" "}
            to track key financial events”
          </div>
        </div>
      </div>
      <div className="disclaimer-note">
        <h1>Disclaimer</h1>
        <p>
          Investors should use this site as a resource for their own research on
          companies, stocks, mutual funds, sectors, and markets. The information
          provided is not investment advice, nor a recommendation to buy or sell
          securities. Users should conduct independent research to form their
          own investment opinions and decisions. Please invest at your own risk;
          we are not liable for any damages incurred. Algoanalytics is not
          responsible for the Accuracy of Strategies.{" "}
        </p>
      </div>
      <NewsletterDialog dialogRef={dialogRef} />
    </section>
  );
};

export default MarketSubscribeSection;


