import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import axios from "axios";
import SearchContent from "../../Views/SearchContent/SearchContent";
import NewsArticleSearch from "../../Views/NewsArticle/NewsArticleSearch";
import AnalyzedTweetSearch from "../../Views/AnalyzedTweet/AnalyzedTweetSearch";
import NoResultsBox from "../../Views/Common/NoResultsBox/NoResultsBox";
import TweetsList from "../../Actions/TweetsList";
import { newsTypes } from "./search.types";
import "./Search.scss";
import { useNavigate } from "react-router-dom";
import SubscribeListingModal from "../../Views/Common/SubscribeListingModal/SubscribeListingModal";
import Navbar from "../LandingV2/Navbar";
import ReactMarkdown from "react-markdown";
/**
 * This React component represents the search functionality within the application.
 *
 * Users can enter search queries to retrieve news and tweet results, and the component
 * displays search results, filters, and additional details for each result.
 *
 * @returns {JSX.Element} The JSX representation of the News component.
 */
const News = () => {
  const [apiResponse, setApiResponse] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [newsData, setNewsData] = useState<any | {}>({} as any);
  // const [wordCloud, setWordCloud] = useState<any | {}>({} as any);
  const [singleNews, setSingleNews] = useState<any | {}>({} as any);
  const [allNews, setAllNews] = useState<newsTypes[] | []>([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [wordCloudData, setWordCloudData] = useState<any>([]);
  const [filterNews, setFilterNews] = useState<newsTypes[] | []>([]);
  const [checkSearchResults, setCheckSearchResults] = useState<any | []>([]);

  const listIndex = useSelector((state: any) => state.NewsListData);
  const newsByWord = useSelector((state: any) => state.IdentifyNews);
  const dispatch = useDispatch();
  const initial = 5;

  const [bar, setBar] = useState(initial);

  const [showSubScribe, setShowSubScribe] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(TweetsList(null));
  }, []);

  // Setting filterType to filter out news or tweets
  useEffect(() => {
    if (filterType === "news") {
      let only_news = newsData.filter((word: { key: string }) => {
        return word.key === "news";
      });
      only_news[0].active = true;
      setAllNews(() => {
        return [...only_news];
      });
      setSingleNews(only_news[0]);
    } else if (filterType === "tweets") {
      let only_tweets = newsData.filter((word: { key: string }) => {
        return word.key === "tweets";
      });
      only_tweets[0].active = true;
      setAllNews(() => {
        return [...only_tweets];
      });
      setSingleNews(only_tweets[0]);
    } else if (filterType === "all") {
      setAllNews(() => {
        return [...newsData];
      });
      newsData[0].active = true;
      setSingleNews(newsData[0]);
    }
  }, [filterType]);

  const getAllNews = async () => {
    try {
      //reset prev
      setWordCloudData([]);

      setIsLoaderVisible(true);

      let response_from_api = await axios.post(
        `${process.env.REACT_APP_SEARCH_URL}/`,
        {
          prompt: searchText,
          get_window: 7,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("response_from_api", response_from_api.data);
      setApiResponse(response_from_api.data.response);

      // let responsefromdummy = testdata;
      let search_result = [];
      let news_response = [];

      let value: any = {};
      let key: string = "";

      // if (response.data?.QnA_Results?.All_QnA_Results) {
      if (false) {
        // const { All_QnA_Results } = response.data.QnA_Results;
        // All_QnA_Results.forEach((result: any, index: any) => {
        //   const { search_results } = result.News_Search_Results;
        //   if (search_results) {
        //     //['news_content1']
        //     let obj = {
        //       text: result.answer,
        //       ids: ["news_" + result.content_name],
        //       key: "news",
        //     };
        //     search_result.push(obj);
        //   }
        // });
        // for ([key, value] of Object.entries(
        //   response.data.News_Search_Results.news
        // )) {
        //   value = value as any;
        //   value["ID"] = "news_" + key;
        //   value["key"] = "news";
        //   news_response.push(value);
        // }
      } else {
        for (const meta of response_from_api.data.meta_data) {
          // Extract the News_Summary from each metadata object
          const newsSummary = meta.News_Summary;

          // Create an object with the extracted News_Summary and other necessary fields
          let obj = {
            text: newsSummary,
            ids: ["news_" + meta.news_ID], // Use news_ID for unique identification
            key: "news",
          };

          // Push the object to the search_result array
          search_result.push(obj);
        }

        //removed to hide tweets
        // for ([key, value] of Object.entries(response.data.Tweets_Search_Results.search_result)) {
        //   let obj = { "text": value, "ids": ["tweets_" + key], "key": "tweets" }
        //   search_result.push(obj)
        // }

        for ([key, value] of Object.entries(
          // response.data.News_Search_Results.news
          response_from_api.data.meta_data
        )) {
          value = value as any;
          value["ID"] = "news_" + key;
          value["key"] = "news";
          news_response.push(value);
        }
      }
      setCheckSearchResults(search_result);
      // console.log("search_result", search_result);
      setWordCloudData(search_result);
      // console.log("news_response", news_response);
      setNewsData(news_response);
      setAllNews(news_response);

      news_response.forEach((element: any) => {
        element.active = false;
        element.filterDate = moment(element.News_date).format("YYYY-MM-DD");
      });

      let sortedList: any = news_response.sort(function (a: any, b: any) {
        let date1: any = new Date(a.News_date);
        let date2: any = new Date(b.News_date);
        return date2 - date1;
      });

      if (listIndex !== null) {
        let findNewsIndex = sortedList.findIndex(
          (news: any) => news.News_ID === listIndex
        );

        if (findNewsIndex === -1) {
          findNewsIndex = 0;
        }

        sortedList[findNewsIndex].active = true;
        setSingleNews(sortedList[findNewsIndex]);
      } else {
        if (sortedList.length) {
          sortedList[0].active = true;
          setSingleNews(sortedList[0]);
        }
        // sortedList.length ? sortedList[0].active = true :""
      }

      setFilterNews(sortedList);
    } catch (err) {
      console.error(err);
      setWordCloudData([]);
    } finally {
      setIsLoaderVisible(false);
    }
  };

  //fetch data on mount
  // useEffect(() => {
  //   getAllNews();
  // }, []);

  useEffect(() => {
    var result: any = [];
    for (var i = 0; i < Math.floor(bar); i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(moment(d).format("YYYY-MM-DD"));
    }

    if (bar > 0) {
      const newsFilter = filterNews.filter((news) => {
        return result.includes(news.filterDate);
      });

      if (newsFilter.length > 0) {
        for (let i = 0; i < newsFilter.length; i++) {
          newsFilter[i].active = false;
        }
        newsFilter[0].active = true;
        setSingleNews(newsFilter[0]);
      } else {
        setSingleNews({});
      }
      setAllNews(newsFilter);
    }
  }, [bar]);

  const update = (values: any) => {
    setBar(parseFloat(values[0]));
    // setFilterDates(result);
  };

  useEffect(() => {
    if (newsByWord.length > 0) {
      const filterNewsByWords = filterNews.filter((news) =>
        newsByWord.includes(news.ID)
      );

      if (filterNewsByWords.length > 0) {
        for (let i = 0; i < filterNews.length; i++) {
          filterNews[i].active = false;
        }

        // let findNewsIndex = filterNews.findIndex(
        //   (news: any) => news.News_ID === filterNewsByWords[0].News_ID
        // );

        filterNewsByWords[0].active = true;
        setAllNews(() => {
          return [...filterNewsByWords];
        });
        setSingleNews(filterNewsByWords[0]);
      } else {
        if (bar > 0) {
          if (filterNews.length > 0) {
            for (let i = 0; i < filterNews.length; i++) {
              filterNews[i].active = false;
            }
            filterNews[0].active = true;
            setSingleNews(filterNews[0]);
          } else {
            setSingleNews({});
          }
          setAllNews(filterNews);
        }
      }
    }
  }, [newsByWord]);

  const handleSearchSubmit = (event: any) => {
    event.preventDefault();

    const subscribed = true; //check if user is premium

    if (subscribed) {
      getAllNews();
    } else {
      //if not premium --> show subscribe popover
      setShowSubScribe(true);
    }
  };

  return (
    <>
      <Navbar />
      <SubscribeListingModal
        value={showSubScribe}
        onHideModal={() => {
          setShowSubScribe(false);
        }}
      />
      <form onSubmit={handleSearchSubmit}>
        <div className="input-group">
          <div className="search-box">
            <input
              type="text"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              className="search-input"
              placeholder="Enter a prompt here"
              // style={{color :"white"}}
            />

            <button
              type="submit"
              // onClick={() => { getAllNews() }}
              className="search-button"
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>

      <div className="disclaimer d-flex justify-content-end ">
        <p>
          <span>Disclaimer * </span>
          {`Please note that the Search Results are currently in the *Beta Phase.`}
        </p>
      </div>
      {wordCloudData.length !== 0 && newsData.length !== 0 ? (
      <div className="semantic-chatbot-container">
        <>
          {(
            <div className="left-side">
              <div className="chat-container">
                {apiResponse ? (
                  <ReactMarkdown>{apiResponse}</ReactMarkdown>
                ) : (
                  <p>No content available</p>
                )}
              </div>
            </div>
          )}
          {(
            <div className="right-side">
              <div className="metadata-box">
                {checkSearchResults[0]?.text === undefined ? (
                  <div className="no-data-box">No MetaData To Display !!!</div>
                ) : (
                  <div className="metabox row mx-auto px-1 meta">
                    <SearchContent
                      setSingleNews={setSingleNews}
                      allNews={allNews}
                      filterType={filterType}
                    />
                    {singleNews?.key === "news" ? (
                      <NewsArticleSearch singleNews={singleNews} />
                    ) : (
                      <AnalyzedTweetSearch singleTweet={singleNews} />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </div>
      ) : (
        <div className="empty-box" style={{ display: "block" }}>
          <NoResultsBox />
        </div>
      )}

      {isLoaderVisible && (
        <div className="loader">
          {" "}
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default React.memo(News);
