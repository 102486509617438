import * as React from 'react';
import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';
const App = () => {
	return (
		<div className={ 'App' }>
			<TVChartContainer/>
		</div>
	);
};

export default App;
