import {
  DynamoDBClient,
  GetItemCommand,
  ScanCommand,
  ScanCommandInput,
  ScanCommandOutput,
} from "@aws-sdk/client-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";


export const dynamodb = new DynamoDBClient({
  region: "ap-south-1",
  // credentials: fromIni(),
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
  },
});

interface StockConfig {
  Stock_Ticker_Symbol: string;
  [key: string]: any; // For any additional attributes
}

// Interface for the function response
interface GetStockConfigsResponse {
  success: boolean;
  data?: StockConfig[];
  count?: number;
  error?: string;
}

export const getTickers = async () => {
  // Query all the items from the DynamoDB table
  const params: ScanCommandInput = {
    TableName: "ConfigTable",
  };

  try {
    const items: StockConfig[] = [];
    let lastEvaluatedKey: Record<string, any> | undefined = undefined;

    // Handle pagination since scan has a limit of 1MB per request
    do {
      if (lastEvaluatedKey) {
        params.ExclusiveStartKey = lastEvaluatedKey;
      }

      const command = new ScanCommand(params);
      const response: ScanCommandOutput = await dynamodb.send(command);

      // Convert DynamoDB records to regular JavaScript objects
      const unmarshalledItems = response.Items?.map(item =>
          unmarshall(item) as StockConfig
      ) || [];

      items.push(...unmarshalledItems);

      lastEvaluatedKey = response.LastEvaluatedKey;
    } while (lastEvaluatedKey);

    // console.log("Items:", items);
    return {
      success: true,
      data: items,
      count: items.length,
    };
  } catch (error) {
    console.error("Error scanning DynamoDB table:", error);
    return {
      success: false,
      error: error instanceof Error ? error.message : "Unknown error occurred",
    };
  }
};
