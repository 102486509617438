import React, { useState, useEffect } from "react";
import axios from "axios";
import { subDays } from "date-fns";
import { ToastContainer, toast } from "react-toastify";

import NewsAggSearchBar from "../../Views/NewsAggregator/SearchBar/NewsAggSearchBar";
import AggregatorNewsTab from "../../Views/NewsAggregator/AggregatorNewsTab/AggregatorNewsTab";
import NoResultsBox from "../../Views/Common/NoResultsBox/NoResultsBox";
import NewSourceDistChart from "../../Views/Graphs/NewsAggregator/NewSourceDistChart";
import AggTreeMapChart from "../../Views/Graphs/NewsAggregator/AggTreeMapChart";
import CandleStick from "../../Views/Graphs/NewsAggregator/CandleStick";
import HeatMapChart from "../../Views/Graphs/NewsAggregator/HeatMapChart";
import AggStackedBarLine from "../../Views/Graphs/NewsAggregator/AggStackedBarLine/AggStackedBarLine";

import Navbar from "../LandingV2/Navbar";

import { getSortOrFilteredArr, getUniqueOptions } from "../../utils/Common";
import {
  filterAndCombineNewsSourceDist,
  filterCandleStickData,
  filterFrequentEntities,
  filterHeatMapData,
  filterNewsTabData,
  filterStackBarLine,
  getAllNewsAggOptionsData,
  getDatesBetweenDates,
  getDifferenceInDays,
} from "../../utils/NewAgrregator";
import useWindowDimemsions from "../../hooks/useWindowDimesnions";
import "react-toastify/dist/ReactToastify.css";
import "./NewsAggregator.scss";
import { Spin } from "antd";
import { getTickers } from "../../utils/awsClient";

//remove newsletter from aggregator path

const toastSettings = {
  position: "top-center",
  autoClose: 200,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "light",
};

/**
 * NewsAggregator Component.
 *
 * This component provides a user interface for aggregating and analyzing news data
 * related to various companies within a specified date range. Users can select a company,
 * choose a date range, and view various charts and graphs representing news sentiment,
 * source distribution, and more.
 *
 * This function is called in \src\Router\Router.tsx
 *
 * @component
 */
const NewsAggregator = () => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 10));
  const [endDate, setEndDate] = useState(new Date());

  // Searchbar state
  const [tickerOptions, setTickerOptions] = useState([]);

  //  //new logic
  // const [selectedStartDate, setSelectedstartDate] = useState(null);
  // const [selectedEndDate, setSelectedEndDate] = useState(null);

  //  //new logic
  // const [freqEntitiesDate, setFreqEntitiesDate] = useState(null);   //sub date range for most freq Entities
  // const [newsSourceDistDate, setNewsSourceDistDate] = useState(null);   //sub date range for News Source Dist

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [errors, setErrors] = useState({
    startDates: false,
    endDates: false,
    companyName: false,
    startDatesErrMsg: "From Date is required",
    endDatesErrMsg: "Till Date is required",
  });

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const [allData, setAllData] = useState({}); //remove later

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [sortValue, setSortValue] = useState("");
  const [optionsData, setOptionsData] = useState([]);

  const [newsTabData, setNewsTabData] = useState([]);
  const [newsTabOriginalData, setNewsTabOriginalData] = useState([]);

  const [candleStickData, setCandleStickData] = useState([]);
  const [newsSentimentVsVolume, setNewsSentimentVsVolume] = useState([]);
  const [heatMapData, setHeatMapData] = useState([]);
  const [newsSourceDist, setNewsSourceDist] = useState({}); //check if array or object
  const [mostFrequentEntities, setMostFrequentEntities] = useState([]);

  //new logic
  // const [allDataFrequentEntities, setAllDataFrequentEntities] = useState({});
  // const [allDataNewsSourceDist, setAllDataNewsSourceDist] = useState({});

  const getTickersData = async () => {
    const tickersDetail = await getTickers();
    // const options = getAllNewsAggOptionsData();
    const options = tickersDetail.success
      ? tickersDetail.data.map((ticker) => {
          return {
            value: ticker.Stock_Ticker_Symbol,
            label: ticker.Ticker_Name,
          };
        })
      : [];
    // console.log("options", options);
    setTickerOptions(options);
  };

  useEffect(() => {
    getTickersData();
  }, []);

  useEffect(() => {
    populateOptions(newsTabOriginalData); //reset
    setSortValue(""); //reset
    setSearchValue(null); //reset
  }, [newsTabOriginalData]);

  useEffect(() => {
    const result = getSortOrFilteredArr(
      newsTabOriginalData,
      searchValue,
      sortValue,
      "news_source",
      "news_date",
      "News_Sentiment"
    ); //"News_Sentiment" currently not present in data
    setNewsTabData(result);
  }, [searchValue, sortValue, newsTabOriginalData]);

  useEffect(() => {
    let errorss = {};

    if (startDate === null) {
      errorss = {
        ...errorss,
        startDates: true,
        startDatesErrMsg: "From Date is required",
      };
    } else if (endDate === null) {
      errorss = {
        ...errorss,
        endDates: true,
        endDatesErrMsg: "Till Date is required",
      };
    } else if (
      startDate !== null &&
      endDate !== null &&
      getDifferenceInDays(startDate, endDate) >= 0
    ) {
      // !==null not needed(already checked) but added cause getDifferenceInDays needs them
      const msg = "From Date must be before Till Date";
      errorss = {
        ...errorss,
        startDates: true,
        endDates: true,
        startDatesErrMsg: msg,
        endDatesErrMsg: msg,
      };
    } else if (
      startDate !== null &&
      endDate !== null &&
      getDifferenceInDays(startDate, endDate) <= -20
    ) {
      // max limit for graph date range is 20 days
      const msg = "max limit is 20 days";
      errorss = {
        ...errorss,
        startDates: true,
        endDates: true,
        startDatesErrMsg: msg,
        endDatesErrMsg: msg,
      };
    } else {
      errorss = {
        ...errorss,
        startDates: false,
        startDatesErrMsg: "From Date is required",
        endDates: false,
        endDatesErrMsg: "Till Date is required",
      };
    }

    setErrors((errors) => ({ ...errors, ...errorss }));
  }, [startDate, endDate]);

  const populateOptions = (data) => {
    const result = getUniqueOptions(data, "news_source");
    setOptionsData(result);
  };

  const handleSortByScore = (event) => {
    setSortValue(event);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleSearchChange = (value) => {
    if (value) {
      setSearchValue(value);
    } else {
      //!value always null by default for antd
      setSearchValue(null);
    }

    setOpen(false);
  };

  const handleShow = () => {
    setOpen(true);
  };

  const handleSearchSumbiit = async (event) => {
    event.preventDefault();

    if (
      errors.startDates === true ||
      errors.endDates === true ||
      errors.companyName === true
    ) {
      return;
    } else if (
      startDate === null ||
      endDate === null ||
      selectedCompany === null
    ) {
      //stop execution
      const errorss = {
        ...errors, // prev errors
        startDates: startDate === null ? true : false,
        endDates: endDate === null ? true : false,
        companyName: selectedCompany === null ? true : false,
      };
      setErrors(errorss);
      return;
    }

    //console.log('code below')

    //reset state
    handleResetState();

    try {
      //get dates
      const dateRange = getDatesBetweenDates(startDate, endDate);
      //console.log('dateRange', dateRange)

      //new logic
      // setSelectedstartDate(() => startDate);
      // setSelectedEndDate(() => endDate);

      setIsLoaderVisible(true);

      //https://algoanalytics-fabric-website.s3.ap-south-1.amazonaws.com/Json_copy/Json/TATAMOTORS.NS/news_aggregator.json
      const { data: allPossibleData } = await axios.get(
        `${process.env.REACT_APP_NEWS_AGGREGATOR}/${selectedCompany}/news_aggregator.json`
      );

      setAllData(() => allPossibleData); //was prev used to pass data to graph, now just used render diff divs/layouts

      const aggNewTabData = allPossibleData.news_articles || {};
      const filteredNewTabData = filterNewsTabData(aggNewTabData, dateRange); //filterNewsTabData  returns array
      setNewsTabData(() => filteredNewTabData);
      setNewsTabOriginalData(() => [...filteredNewTabData]);

      const candleData = allPossibleData.Stock_data || {};
      setCandleStickData(() => filterCandleStickData(candleData, dateRange)); //filterCandleStickData returns array

      const barGraphData = allPossibleData.sentiment_stacked_barchart || {};
      const lineGraphData = allPossibleData.news_distrib_across_days || {};
      const stackedBarLine = filterStackBarLine(
        barGraphData,
        lineGraphData,
        dateRange
      ); //filterStackBarLine returns array
      setNewsSentimentVsVolume(() => stackedBarLine);

      const heatMap = allPossibleData.event_label_heatmap || {};
      setHeatMapData(() => filterHeatMapData(heatMap, dateRange)); //filterHeatMapData returns array

      const newsSourceDistribution = allPossibleData.news_source_distrib || {};
      const finalData = filterAndCombineNewsSourceDist(
        newsSourceDistribution,
        dateRange
      );
      setNewsSourceDist(() => finalData); //filterAndCombineNewsSourceDist returns object
      //new logic
      // handleNewsSourceDistDate(startDate);
      // setAllDataNewsSourceDist(() => newsSourceDistribution); //all data

      const mostFrequentEntities = allPossibleData.ner_org_freq || {};
      const entityData = filterFrequentEntities(
        mostFrequentEntities,
        dateRange
      );
      setMostFrequentEntities(() => entityData); //filterAndCombineNewsSourceDist returns array
      //console.log('setMostFrequentEntities', entityData);
      //new logic
      // handleFrequentEntitiesDate(startDate); //default date
      // setAllDataFrequentEntities(() => mostFrequentEntities); //all data

      toast.success("Success", toastSettings);
    } catch (err) {
      // if (err.response && err.response.status >= 400) {
      toast.error("No news articles found", toastSettings);
      //};
      handleResetState();
    } finally {
      setIsLoaderVisible(false);
    }
  };

  const handleCompanyChange = (value) => {
    if (value) {
      setSelectedCompany(value);
      setErrors({ ...errors, companyName: false });
    } else {
      setSelectedCompany(null);
      setErrors({ ...errors, companyName: true });
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleResetState = () => {
    //console.log('handleResetState');

    //reset
    setAllData(() => {});
    setNewsTabData(() => []);
    setNewsTabOriginalData(() => []);

    setCandleStickData(() => []);
    setNewsSentimentVsVolume(() => []);
    setHeatMapData(() => []);
    setNewsSourceDist(() => {});
    setMostFrequentEntities(() => []);

    // //new logic
    // handleNewsSourceDistDate(null); //default date
    // setAllDataNewsSourceDist(() => {}); //all data

    // //new logic
    // handleFrequentEntitiesDate(null); //default date
    // setAllDataFrequentEntities(() => {}); //all data

    // //new logic
    // setSelectedstartDate(() => null);
    // setSelectedEndDate(() => null);
  };

  // const handleFrequentEntitiesDate = (date) => {
  //     setFreqEntitiesDate(date);
  // };

  // const handleNewsSourceDistDate = (date) => {
  //     setNewsSourceDistDate(date);
  // };

  const { width } = useWindowDimemsions();

  // const filteredFrequentEntities = useMemo(
  //     () => filteredFreqEntities(allDataFrequentEntities, freqEntitiesDate),
  //     [allDataFrequentEntities, freqEntitiesDate]
  // );

  // const filteredNewsSourceDistrib = useMemo(
  //     () => filterNewsSourceDist(allDataNewsSourceDist, newsSourceDistDate),
  //     [allDataNewsSourceDist, newsSourceDistDate]
  // );

  return (
    <>
      <Navbar />
      <section>
        <div className="container-fluid">
          <NewsAggSearchBar
            onSearchSumbiit={handleSearchSumbiit}
            selectedCompany={selectedCompany}
            onCompanyChange={handleCompanyChange}
            options={tickerOptions}
            errors={errors}
            startDate={startDate}
            onChangeStartDate={handleStartDate}
            endDate={endDate}
            onChangeEndDate={handleEndDate}
          />
        </div>
      </section>

      <Spin spinning={isLoaderVisible}>
        <section>
          <div className="container-fluid">
            <div>
              {Object.keys(allData || {})?.length > 0 ? (
                <>
                  {/* <div className="date-range-cards-flex-box mx-auto" >

                                        <SubDateRange
                                            title={"Most Frequent Entities"}
                                            key={"freqEntitiesDate"}
                                            value={freqEntitiesDate}
                                            onChange={handleFrequentEntitiesDate}
                                            minDate={new Date(selectedStartDate)}
                                            maxDate={new Date(selectedEndDate)}
                                        />


                                        <SubDateRange
                                            title={"News Source Distribution"}
                                            key={"newsSourceDistDate"}
                                            value={newsSourceDistDate}
                                            onChange={handleNewsSourceDistDate}
                                            minDate={new Date(selectedStartDate)}
                                            maxDate={new Date(selectedEndDate)}
                                        />

                                </div>

                                <div className="row mx-auto" >

                                        <AggTreeMapChart
                                            key={"three"}
                                            data={filteredFrequentEntities}
                                        />

                                        <NewSourceDistChart
                                            key={"four"}
                                            data={filteredNewsSourceDistrib}
                                        />

                                </div> */}

                  <div className="row mx-auto">
                    <div className="col-xl-8">
                      <div className="row mb-2">
                        <AggStackedBarLine data={newsSentimentVsVolume} />

                        <NewSourceDistChart
                          //  key={"one"}
                          data={newsSourceDist}
                        />
                      </div>

                      <div className="row  mb-2">
                        <AggTreeMapChart
                          // key={"two"}
                          data={mostFrequentEntities}
                        />

                        <CandleStick data={candleStickData} />
                      </div>

                      <div className="row  mb-2">
                        <HeatMapChart data={heatMapData} screenWidth={width} />
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <AggregatorNewsTab
                        data={newsTabData}
                        open={open}
                        onOpenChange={handleOpenChange}
                        onShow={handleShow}
                        optionsData={optionsData}
                        onSearchChange={handleSearchChange}
                        searchValue={searchValue}
                        onsortByScore={handleSortByScore}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className=" mb-4 row "
                  style={{
                    background: "#fff",
                    borderRadius: "5px",
                    padding: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                  }}
                >
                  <div className="col">
                    <div
                      style={{
                        minHeight: "65svh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <NoResultsBox
                        displayText={
                          isLoaderVisible ? `searching...` : `Select Company`
                        }
                        showEmptyImage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            :
          </div>
        </section>
      </Spin>

      <ToastContainer />
      {/* {isLoaderVisible && (
                <div className="loader">
                    {" "}
                    <CircularProgress />
                </div>
            )} */}
    </>
  );
};

export default NewsAggregator;
