import React, { useEffect, useState } from "react";
import { Table, Popover, OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./DashBoardCards.css";
import NavigateMenu from "../../../Actions/NavigateMenu";

const DashBoardTable = ({ tableData, alertsData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {

    const updatedData = tableData.map((summary) => {
      const alerts = alertsData[summary.Stock_Ticker];
      const alertsCount = alerts ? alerts.length : 0;
      const alertsColor = `rgb(0, 153, 51, ${alertsCount / 10})`;
      return {
        ...summary,
        Today_News_Alerts: alertsCount,
        Today_News_Alert_colors: alertsColor,
        alerts: alerts || [],
      };
    });
    setUpdatedData(updatedData);
  }, [tableData]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      const isSameKey = prevConfig.key === key;
      const direction =
        isSameKey && prevConfig.direction === "asc" ? "desc" : "asc";
      return { key, direction };
    });
  };

  const sortedData = () => {
    const { key, direction } = sortConfig;
    if (!key) return updatedData;

    return [...updatedData].sort((a, b) => {
      const getValue = (item) => {
        if (key === "Trading_Amount") {
          return item.Today_Volume / 10000000;
        } else if (key === "Today_News_Sentiment") {
          return item.Today_News_Sentiment === "NA"
            ? -Infinity
            : item.Today_News_Sentiment;
        } else {
          return item[key];
        }
      };

      const aValue = getValue(a);
      const bValue = getValue(b);

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const handleTickerChange = (ticker) => {
    window.localStorage.setItem("tickerId", ticker);
  };

  const renderAlertsPopover = (alerts) => {
    const alertTypes = {
      ADX_change: "ADX_Change",
      Top_stories_using_TSA_count: "Top Stories",
      price_change_alerts: "Price Change",
      StockVolume_change: "Stock Volume Change",
      events_with_summary: "Events",
      news_count: "News Count",
      ATR_change: "ATR Change",
    };
  
      return (
        <Popover id="alerts-popover" className="custom-popover">
        <Popover.Body>
          {alerts.length > 0 ? (
            <div>
              {alerts.map((alert, index) => {
                const [alertKey, alertData] = Object.entries(alert)[0];
                return (
                  <div key={index}>
                    <ul>
                      {Object.entries(alertData).map(([key, value], idx) => {
                        if (alertKey === "price_change_alerts") {
                          return (
                            <div key={idx}>
                              {key === "alerts_open_highest" ? (
                                <div className="title">
                                  <strong>Open Price :{" "}</strong> 
                                  <span style={{ color: "green" }}>High</span>{" "}
                                  {value.live_day_open.toFixed(2)}
                                </div>
                              ) : key === "alerts_open_lowest" ? (
                                <div className="title">
                                 <strong>Open Price :{" "}</strong> 
                                  <span style={{ color: "red" }}>Low</span>{" "}
                                  {value.live_day_open.toFixed(2)}
                                </div>
                              ) : key === "alerts_close_highest" ? (
                                <div className="title">
                                 <strong>Close Price :{" "}</strong> 
                                  <span style={{ color: "green" }}>High</span>{" "}
                                  {value.live_day_close.toFixed(2)}
                                </div>
                              ) : key === "alerts_close_lowest" ? (
                                <div className="title">
                                  <strong>Close Price :{" "}</strong>
                                  <span style={{ color: "red" }}>Low</span>{" "}
                                  {value.live_day_close.toFixed(2)}
                                </div>
                              ) : key === "alerts_price_change" ? (
                                <div className="title">
                                  <strong>Percentage Change :{" "} </strong> 
                                  {value.percentage_change > 0 ? (
                                    <span style={{ color: "green" }}>
                                      {value.percentage_change.toFixed(2)}%
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      {value.percentage_change.toFixed(2)}%
                                    </span>
                                  )}
                                </div>
                              ) : key === "top_n_price_change" ? (
                                <div className="title">
                                  <strong>Percentage Change :{" "} </strong> 
                                  {value.percentage_change > 0 ? (
                                    <span style={{ color: "green" }}>
                                      {value.percentage_change.toFixed(2)}%
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      {value.percentage_change.toFixed(2)}%
                                    </span>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
      
                        if (alertKey === "ADX_change") {
                          return (
                            <div key={idx}>
                              {key === "weak_trend" ? (
                                <div className="title">
                                  <strong>ADX Change :{" "}</strong>
                                  <span style={{ color: "red" }}>Weak Trend</span>{" "}
                                  {parseInt(value.ADX).toFixed(2)}
                                </div>
                              ) : key === "strong_trend" ? (
                                <div className="title">
                                  <strong>ADX Change :{" "}</strong>
                                  <span style={{ color: "green" }}>Strong Trend</span>{" "}
                                  {parseInt(value.ADX).toFixed(2)}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
      
                        if (alertKey === "ATR_change") {
                          return (
                            <div key={idx}>
                              {key === "low_volatility" ? (
                                <div className="title">
                                  <strong>ATR Change :{" "}</strong>
                                  <span style={{ color: "red" }}>Low Volatility</span>{" "}
                                  {parseInt(value.Last_ATR).toFixed(2)}
                                </div>
                              ) : key === "high_volatility" ? (
                                <div className="title">
                                  <strong>ATR Change :{" "}</strong>
                                  <span style={{ color: "green" }}>
                                    High Volatility
                                  </span>{" "}
                                  {parseInt(value.Last_ATR).toFixed(2)}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
  
                        if (alertKey === "dpo_alert") {
                          return (
                            <div key={idx}>
                              {key === "Downward_cycle" ? (
                                <div className="title">
                                  <strong>DPO Change :{" "}</strong>
                                  <span style={{ color: "red" }}>Downwards</span>{" "}
                                  {parseInt(value.Current_dpo).toFixed(2)}
                                </div>
                              ) : key === "high_volatility" ? (
                                <div className="title">
                                  <strong>DPO Change :{" "}</strong>
                                  <span style={{ color: "green" }}>
                                    Upwards
                                  </span>{" "}
                                  {parseInt(value.Current_dpo).toFixed(2)}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
  
                        if (alertKey === "AD_alert") {
                          return (
                            <div key={idx}>
                              {key === "Distribution" ? (
                                <div className="title">
                                  <strong>AD Alert : {" "}</strong>
                                  <span style={{ color: "red" }}>Distribution</span>{" "}
                                  {value["Current A/D"].toFixed(2)}
                                </div>
                              ) : key === "Accumulation" ? (
                                <div className="title">
                                  <strong>AD Alert : {" "}</strong>
                                  <span style={{ color: "green" }}>
                                    Accumulation
                                  </span>{" "}
                                  {value["Current A/D"].toFixed(2)}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
                     
  
                        if (alertKey === "stock_vs_index") {
                          return (
                            <div key={idx}>
                              {Array.isArray(value) && value.length > 0 ? (
                                value.map((indexAlert, subIdx) => (
                                  <div key={subIdx}>
                                    <div className="title">
                                      <strong>Stock vs Index :</strong>
                                    
                                    <div>Alert: {indexAlert.Alert}</div>
                                    <div>Index: {indexAlert.Index}</div>
                                    <div>
                                      Index % Change:{" "}
                                      {indexAlert.Index_pct_change > 0 ? (
                                        <span style={{ color: "green" }}>
                                          {indexAlert.Index_pct_change.toFixed(2)}%
                                        </span>
                                      ) : (
                                        <span style={{ color: "red" }}>
                                          {indexAlert.Index_pct_change.toFixed(2)}%
                                        </span>
                                      )}
                                    </div>
                                    <div>
                                      Stock % Change:{" "}
                                      {indexAlert.Stock_pct_change > 0 ? (
                                        <span style={{ color: "green" }}>
                                          {indexAlert.Stock_pct_change.toFixed(2)}%
                                        </span>
                                      ) : (
                                        <span style={{ color: "red" }}>
                                          {indexAlert.Stock_pct_change.toFixed(2)}%
                                        </span>
                                      )}
                                    </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                null
                              )}
                            </div>
                          );
                        }
                        
  
                        
                        if (alertKey === "Top_stories_using_TSA_count") {
                          return (
                            <div key={idx} className="title">
                              <strong>Top Stories :</strong>
                              <div>{value.title}</div>
                              {/* <div>
                                <a
                                  href={value.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Read More
                                </a>
                              </div> */}
                            </div>
                          );
                        }
                        
  
                        if (alertKey === "events_with_summary") {
                          return (
                            <div key={idx}>
                              <div className="title">Max Event :{" "} <span style={{fontStyle:"bold"}}>{value.max_event}</span></div>
                              <div className="title">Event Count :{" "} <span style={{fontStyle:"bold"}}>{value.max_event_count}</span></div>
                            </div>
                          );
                        }
      
                        if (alertKey === "StockVolume_change") {
                          return (
                            <div key={idx}>
                              {key === "Stock_volume_high" ? (
                                <div className="title">
                                  <strong>Stock Volume :{" "}</strong>
                                  <span style={{ color: "green" }}>High</span>{" "}
                                  {parseInt(value["Current Volume"]).toFixed(2)}
                                </div>
                              ) : key === "Stock_volume_low" ? (
                                <div className="title">
                                  <strong>Stock Volume :{" "}</strong>
                                  <span style={{ color: "red" }}>Low</span>{" "}
                                  {parseInt(value["Current Volume"]).toFixed(2)}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
      
                        if (alertKey === "news_count") {
                          return (
                            <div key={idx}>
                              <div className="title"> <strong>News Count :</strong>  <span style={{fontStyle:"bold"}}>{value.news_count}</span></div>
                            </div>
                          );
                        }
                        if (alertKey === "Momentum_change") {
                          return (
                            <div key={idx}>
                              {key === "Oversold" ? (
                                <div className="title"> 
                                 <strong>Momentum Change :{" "}</strong> 
                                  <span style={{ color: "red" }}>Oversold</span>{" "}
                                </div>
                              ) : key === "Overbought" ? (
                                <div className="title">
                                  <strong>Momentum Change :{" "}</strong>
                                  <span style={{ color: "green" }}>Overbought</span>{" "}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
                        if(alertKey === "VWAP_change"){
                          return (
                            <div key={idx}>
                              {key === "Downtrend" ? (
                                <div className="title">
                                  <strong>VWAP :{" "}</strong>
                                  <span style={{ color: "red" }}>Down</span>{" "}
                                  {parseInt(value.current_vwap).toFixed(2)}
                                </div>
                              ) : key === "Uptrend" ? (
                                <div className="title">
                                  <strong>VWAP :{" "}</strong>
                                  <span style={{ color: "green" }}>Up</span>{" "}
                                  {parseInt(value.current_vwap).toFixed(2)}
                                </div>
                              ) : null}
                            </div>
                          );
                        }
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          ) : (
            "No alerts available"
          )}
        </Popover.Body>
      </Popover>    
      );
    };

  const columns = [
    { key: "Stock_Ticker", label: "Stock Ticker" },
    {
      key: "Today_News_Alerts",
      label: "Total Alerts",
      colorKey: "Today_News_Alert_colors",
      content: (summary) => summary.alerts.length || "0",
    },
    {
      key: "Trading_Amount",
      label: "Volume (Crs)",
      colorKey: "Today_Volume_colors",
      content: (summary) => (summary.Today_Volume / 10000000).toLocaleString("en-IN"),
    },
    {
      key: "Today_Returns",
      label: "Returns",
      colorKey: "Today_Returns_colors",
      content: (summary) => `${summary.Today_Returns} %`,
    },
    {
      key: "Today_News_Volume",
      label: "News Volume",
      colorKey: "Today_News_Volume_colors",
      content: (summary) => summary.Today_News_Volume,
    },
    {
      key: "Today_News_Sentiment",
      label: "Sentiments",
      colorKey: "Today_News_Sentiment_colors",
      content: (summary) =>
        summary.Today_News_Sentiment.toString() === "NA"
          ? "No Data"
          : summary.Today_News_Sentiment,
    },
  ];

  return (
    <div className="main-div">
      <Table responsive>
        <thead className="table-head-column">
          <tr className="table-header-height">
            {columns.map(({ key, label }) => (
              <th
                key={key}
                style={{ cursor: "pointer" }}
                onClick={() => handleSort(key)}
              >
                {label} <i className="fas fa-sort"></i>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData().map((summary, index) => (
            <tr key={index}>
              <td
                // className="td_hover"
                onClick={() => {
                  handleTickerChange(summary.Stock_Ticker);
                  navigate("/detail");
                  dispatch(NavigateMenu(0));
                }}
                style={{ cursor: "pointer" }}
              ><div>
                <div style={{display:"flex",alignItems:"center",gap:"2rem",justifyContent:"center"}}>
              <img
                src={`${process.env.REACT_APP_COMPANY_DOC}/Marquee_logos/${
                  summary.Stock_Ticker?.split(".")[0]
                }.svg`}
                alt="logo"
                style={{ width: "40px", height: "40px",borderRadius:"50%" }}
              />
              <div style={{
                width:"150px",
                textAlign:"left"
              }}>
              {summary.Stock_Ticker}
              </div>
                
            </div>
              </div>
              </td>
              {columns.slice(1).map((column, colIndex) => (
                <td key={colIndex}>
                  {column.key === "Today_News_Alerts" ? (
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      overlay={renderAlertsPopover(summary.alerts)}
                    >
                      <button
                        className="btn"
                        style={{ backgroundColor: summary[column.colorKey] }}
                        onClick={() => {
                          handleTickerChange(summary.Stock_Ticker);
                          navigate("/trending", {
                            state: { ticker: summary.Stock_Ticker },
                          });
                          dispatch(NavigateMenu(2));
                        }}
                      >
                        {column.content(summary)}
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <button
                      className="btn"
                      style={{ backgroundColor: summary[column.colorKey] }}
                    >
                      {column.content(summary)}
                    </button>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DashBoardTable;
