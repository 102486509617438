// setGlobalTicker.js
let globalTicker = 'ASIANPAINT.NS'; // default ticker
const subscribers = new Set();

export function getGlobalTicker() {
  return globalTicker;
}

export function setGlobalTicker(newTicker) {
  console.log(`Setting global ticker from ${globalTicker} to ${newTicker}`);
  if (globalTicker !== newTicker) {
    globalTicker = newTicker;
    // Notify all subscribers of the change
    subscribers.forEach(callback => callback(globalTicker));
  }
}

export function onTickerChange(callback) {
  subscribers.add(callback);
  // Return unsubscribe function
  return () => subscribers.delete(callback);
}