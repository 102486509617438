import {
	make1DHistoricalRequest,
	make5mHistoricalRequest,
	barsHistorical,
	barsLive,
	
} from "./helpers.js";
import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js";
import { getGlobalTicker, onTickerChange } from "./setGlobalTicker.js";
const lastBarsCache = new Map();

let ticker = getGlobalTicker();

// Subscribe to ticker changes
onTickerChange((newTicker) => {
    ticker = newTicker;
    console.log("Ticker updated in datafeed:", ticker);
    // Clear caches when ticker changes
    lastBarsCache.clear();
});
// DatafeedConfiguration implementation
const configurationData = {
	// Represents the resolutions for bars supported by your datafeed
	supported_resolutions: ["5", "1D"],
};

function setBars(data, bars) {
	if (!data || !Array.isArray(data)) {
		console.error("Invalid data format:", data);
		return;
	}

	data.forEach((bar) => {
		let barTime, low, high, open, close, volume;
		// Check if the bar follows the historical data format with "date"
		if (bar.date) {
			barTime = new Date(bar.date).getTime() / 1000;
			low = bar.low;
			high = bar.high;
			open = bar.open;
			close = bar.close;
			volume = bar.volume;
		}
		// Otherwise, assume it is in the new format with "timestamp"
		else if (bar.timestamp) {
			barTime = bar.timestamp; // already in seconds
			low = bar.dayLow;
			high = bar.dayHigh;
			open = bar.open;
			close = bar.price;
			volume = bar.volume;
		} else {
			console.error("Unrecognized bar format:", bar);
			return;
		}

		bars.push({
			time: barTime * 1000, // Convert seconds to milliseconds
			low,
			high,
			open,
			close,
			volume,
			isBarClosed: true,
			isLastBar: false,
		});
	});
}

export default {
	onReady: (callback) => {
		console.log("[onReady]: Method call");
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		console.log("[searchSymbols]: Method call");
		// const symbols = await getAllSymbols();
		// const newSymbols = symbols.filter(symbol => {
		// 	const isExchangeValid = exchange === '' || symbol.exchange === exchange;
		// 	const isFullSymbolContainsInput = symbol.full_name
		// 		.toLowerCase()
		// 		.indexOf(userInput.toLowerCase()) !== -1;
		// 	return isExchangeValid && isFullSymbolContainsInput;
		// });
		//   onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
		extension,
	) => {
		console.log("[resolveSymbol]: Method call");
		// const symbols = await getAllSymbols();
		// const symbolItem = symbols.find(({
		// 	full_name,
		// }) => full_name === symbolName);
		// if (!symbolItem) {
		// 	console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
		// 	onResolveErrorCallback('cannot resolve symbol');
		// 	return;
		// }

	
		//Pass ticker and name props
		// Symbol information object
		const symbolInfo = {
			ticker: ticker,
			// name: "ASIAN PAINTS",
			// description: "ASIAN PAINTS",
			// type: "CHEMICAL",
			session: "0915-1530",
			timezone: "Asia/Kolkata",
			exchange: "NSE",
			minmov: 1,
			pricescale: 100,
			has_intraday: true,
			intraday_multipliers: ["5"], //Keep same as Resolution
			has_no_volume: true,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: "streaming",
		};

		console.log("[resolveSymbol]: Symbol resolved");
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (
		symbolInfo,
		resolution,
		periodParams,
		onHistoryCallback,
		onErrorCallback,
	) => {
		console.log("[getBars]: Method call");
		let bars = [];
		try {
			if (resolution === "1D") {
				// Fetch historical data for daily resolution
				barsHistorical.length = 0
				bars = barsHistorical;
				const dataGot = await make1DHistoricalRequest(ticker); 
				const data = dataGot.historical;
				console.log("data is", data);

				if (!data || data.length === 0) {
					console.log("no data received");
					onHistoryCallback([], { noData: true });
					return;
				}

				setBars(data, bars);
				bars.reverse();
				// Mark the last bar as the final one
				if (bars.length > 0) {
					bars[bars.length - 1].isLastBar = true;
				}
			} else if (resolution === "5") {
				// Use the live bars stored in barsLive
				bars = barsLive;
				const data = await make5mHistoricalRequest(ticker); 
				console.log("data is", data);

				if (!data || data.length === 0) {
					console.log("no data received");
					onHistoryCallback([], { noData: true });
					return;
				}
				setBars(data, bars);
				bars.reverse();
			}
			if (bars.length === 0) {
				onHistoryCallback([], { noData: true });
				return;
			}

			// Cache the last bar
			lastBarsCache.set(symbolInfo.ticker, {
				...bars[bars.length - 1],
			});

			console.log(bars);
			console.log(`[getBars]: returned ${bars.length} bar(s)`);
			onHistoryCallback(bars, { noData: false });
		} catch (error) {
			console.log("[getBars]: Get error", error);
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscriberUID,
		onResetCacheNeededCallback,
	) => {
		console.log(
			"[subscribeBars]: Method call with subscriberUID:",
			subscriberUID,
		);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		console.log(
			"[unsubscribeBars]: Method call with subscriberUID:",
			subscriberUID,
		);
		unsubscribeFromStream(subscriberUID);
	},
};
