import React, { useEffect, useState, useContext,useMemo } from "react";
import "./dashboard.scss";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { searchSummary, resetSummary } from "../../Actions/summary";
import DashboardHeader from "../../Views/Dashboard/DashboardTableHeader/DashboardHeader";
import DashBoardTable from "../../Views/Dashboard/DashBoardTable/DashBoardTable";
import {
  getCorrelationState,
  getDashTableCombinedData,
  hideProbablityAtDateTime,
  sortDashboardTableRows,
} from "../../utils/DashBoard";

import Navbar from "../LandingV2/Navbar";
import { AuthContext } from "../../context/AuthContext";
// import alertData from "../../data/alerts/alerts.json";

/**
 * This React component represents the Dashboard of an application.
 *
 * The Dashboard fetches summary data and sentiment correlation data from an API,
 * allows users to filter and search the data, and displays it in a table format.
 *
 * This function is called in \src\Router\Router.tsx
 *
 * @component
 */
const Dashboard = () => {
  const dispatch = useDispatch();

  const [optionsData, setOptionsData] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const { search: searchFilter, filter: headerFilter } = useSelector(
    (state) => state.FilterSummary
  );

  const [updateDate1, setUpdateDate1] = useState("");
  const [updateDate2, setUpdateDate2] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [displayProbScore, setDisplayProbScore] = useState(false);
  const [additionalFilters, setAdditionalFilters] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //new table data
  const [copyTableData, setCopyTableData] = useState([]); //unmodified data  // search dropdown options, search and select takes this data, 1---> always all data
  const [tableData, setTableData] = useState([]); //modified data --> used in tbale only, sorting done on this data, 1---> data array can be reduced
  const [sortVal, setSortVal] = useState("asc");
  const [alertCount, setalertCount] = useState({});
  const [stocks, setStocks] = useState([]);

  /**
   * Show Nifty50 filter
   * Can be null, true or false
   */
  const { userInfo } = useContext(AuthContext); // Use AuthContext
  const isLoggedIn = userInfo !== null;

  const [showOnlyNifty50, setShowOnlyNifty50] = useState("all");

  useEffect(() => {
    (async () => {
      try {
        setIsLoaderVisible(true);
        window.localStorage.removeItem("tickerId");

        //summary axios function
        const { data: summaryData } = await axios.get(
          `${process.env.REACT_APP_API_URL}/summary.json`
        );

        //stockprice_sentiment_correlation
        const { data: sentimentCorrelation } = await axios.get(
          `${process.env.REACT_APP_API_URL}/stockprice_sentiment_correlation_results.json`
        );

        //use dummy data file for news alerts
        // const newsAlerts = alertData;
        const { data: newsAlerts } = await axios.get(
          `${process.env.REACT_APP_COMPANY_DOC}/Alerts/alerts_count.json`
        );
        setalertCount(newsAlerts);

        if (summaryData) {
          window.localStorage.setItem("updateDate1", summaryData.Last_Updated);
          setUpdateDate1(summaryData.Last_Updated);

          window.localStorage.setItem(
            "updateDate2",
            summaryData.Last_Updated_Stocks
          );
          setUpdateDate2(summaryData.Last_Updated_Stocks);
        }

        if (
          sentimentCorrelation.Items &&
          sentimentCorrelation.Items.length > 0 &&
          summaryData &&
          summaryData.summary
        ) {
          const dataObject = getCorrelationState(sentimentCorrelation.Items); //done for faster iteration for items > 50 in array , done only once
          const newData = getDashTableCombinedData(
            summaryData.summary,
            dataObject,
            newsAlerts
          ); //add probability score to table data prev so can sort it later

          console.log(
            "sentiment score 123",
            sentimentCorrelation.Items,
            newData
          );

          setCopyTableData(newData);
          populateOptions(newData);
        }
      } catch (ex) {
        //console.log(ex)
      } finally {
        setIsLoaderVisible(false);
      }
    })();

  //runs only on mount or page refresh
  setDisplayProbScore(hideProbablityAtDateTime());
  }, []); 

  // console.log("SUMMARY", tableData, copyTableData);

  const handleModalClose = () => {
    setModalOpen(() => false);
  };

  //reruns when back to page or persist page state
  //reuns also when copyTableData state sets or changed
  //sets state instead  of setTableData(newData) in empty dependency useffect --> cause check prev response on mount/page refresh;
  useEffect(() => {
    //------------------logic for filter or search starts-----------------------

    if (headerFilter !== "All" && searchFilter === null) {
      // console.log('111111')
      const filterBySector = copyTableData.filter(
        (summary) => summary.Sector === headerFilter
      );
      setTableData(filterBySector);
    } else if (headerFilter === "All" && searchFilter === null) {
      //only when mui select used, normally default
      //console.log('22222')
      setTableData(copyTableData);
    } else if (
      (headerFilter === "All" || headerFilter === "") &&
      searchFilter !== null
    ) {
      // console.log('3333')
      const filterByStockTicker = copyTableData.filter(
        (summary) => summary.Stock_Ticker === searchFilter
      );
      setTableData(filterByStockTicker);
    }
    
    

    //------------------logic for filter or search ends-----------------------
  }, [headerFilter, searchFilter, copyTableData]);


  const populateOptions = (data) => {
    if (data?.length === 0) {
      setOptionsData([]);
    } else {
      const arr = [];
      data.forEach((item, index) => {
        arr.push({
          value: item?.Stock_Ticker,
          label: item?.Stock_Ticker,
        });
      });

      setOptionsData(arr);
    }
  };

  const handleSearchChange = (value) => {
    if (copyTableData?.length > 0 && value) {
      dispatch(searchSummary(value));
    } else if (!value) {
      dispatch(resetSummary());
    }
  };

  const handleSortData = (property) => {
    //use modified tableData state for sort -->  cause else all data appears

    if (tableData?.length > 0 && sortVal && property) {
      const newSortValue = sortVal === "asc" ? "desc" : "asc";

      const newData = sortDashboardTableRows(tableData, property, newSortValue);
      // console.log('check', tableData, newData);
      setTableData(newData); //new data

      setSortVal(newSortValue);
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      setAdditionalFilters([
        { value: "Momentum_Value_Strategy_Stock", label: "Momentum & Value Strategy Stocks" },
      ]);
    } else {
      setAdditionalFilters([]);
    }
  }, [isLoggedIn]);

  const handleSortbyTrading = () => {
    //use modified tableData state for sort -->  cause else all data appears

    if (tableData?.length > 0 && sortVal) {
      //sortFilter is asc or desc,

      const newSortValue = sortVal === "asc" ? "desc" : "asc";

      const order = newSortValue === "desc" ? -1 : 1;

      const calculateA = (a) => (a.Today_Volume * a.yestarday_close) / 10000000;
      const calculateB = (b) => (b.Today_Volume * b.yestarday_close) / 10000000;

      const newData = [...tableData].sort((a, b) =>
        calculateA(a) > calculateB(b)
          ? order
          : calculateB(b) > calculateA(a)
          ? -order
          : 0
      );

      setTableData(newData); //new data

      setSortVal(newSortValue);
    }
  };


  console.log(showOnlyNifty50);
  // Filter by Nifty50 if not all
  const filteredTableData = useMemo(() => {
    let data = tableData;
    
    if (showOnlyNifty50 === "nifty") {
      return data.filter((item) => item.Is_Nifty50 === "1");
    } else if (showOnlyNifty50 === "Momentum_Value_Strategy_Stock" && isLoggedIn) {
      return data.filter((item) => item.Momentum_Value_Strategy_Stock === "1");
    }
    
    return data;
  }, [tableData, showOnlyNifty50, isLoggedIn]);
  
  
  
  // filter out top 10 stocks by absolute value of returns
  useEffect(() => {
    const topStocks = filteredTableData.sort(
      (a, b) => Math.abs(b.Today_Returns) - Math.abs(a.Today_Returns)
    );
    setStocks(topStocks.slice(0, 10));
  
  }, [filteredTableData]);

  return (
    <>
      <Navbar />
      <div className="dashboard">
      <div className="stock-marquee">
      <div className="stock-marquee-container">
        <div className="stock-marquee-item">
          {stocks.map((stock, index) => (
            <div className="stock-marquee-item-content" key={index}>
                <img
                src={`${process.env.REACT_APP_COMPANY_DOC}/Marquee_logos/${
                  stock.Stock_Ticker?.split(".")[0]
                }.svg`}
                className="stock-marquee-item-content-logo"
                alt="logo"
              />
              <div className="stock-marquee-item-content-name">{stock.Stock_Ticker}</div> |
              <div className={`stock-marquee-item-content-price ${stock.Today_Returns >= 0 ? 'positive' : 'negative'}`}>
                {stock.Today_Returns.toFixed(2)}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
        <DashboardHeader
          updateDate1={updateDate1}
          updateDate2={updateDate2}
          searchFilter={searchFilter}
          optionsData={optionsData}
          onSearchChange={handleSearchChange}
          displayProbScore={displayProbScore} //hide or show prob score legend and table column
          showOnlyNifty50={showOnlyNifty50}
          setShowOnlyNifty50={setShowOnlyNifty50}
          additionalFilters={isLoggedIn ? additionalFilters : []}
        />

        <div id="feedback">
          <Button onClick={() => setModalOpen(() => true)}>FEEDBACK</Button>
        </div>

        <Dialog
          open={modalOpen}
          fullScreen={fullScreen}
          onClose={handleModalClose}
        >
          <DialogTitle>FEEDBACK FORM</DialogTitle>
          <div>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLScT6B_3AInFiJn-Ts5d0wmaKvj_qWiGpRZETds5RJ-DFL6Jzg/viewform?embedded=true"
              width="600"
              height="800"
              frameBorder="0"
              title="Feedback Form"
            >
              Loading…
            </iframe>
          </div>
        </Dialog>

        <DashBoardTable
          displayProbScore={displayProbScore}
          tableData={filteredTableData}
          onSortData={handleSortData}
          onSortByTrading={handleSortbyTrading}
          alertsData={alertCount}
        />

        <div className="row mt-1 mb-4 ps-2">
          <p className="disclaimer-dashboard">
            <span>Disclaimer * </span>
            {/* {`The News/Tweets Volume and Sentiments are for the last 24 hours.`} */}
            {`The News Volume and Sentiments are for the last 24 hours.`}
          </p>
        </div>

        {isLoaderVisible && (
          <div className="loader">
            {" "}
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
