import React from "react";
import Plot from "react-plotly.js";
import { GraphData, FilterData } from "./Home";

interface NormalizedLineGraphProps {
  graphData: GraphData | null;
  universeValue?: string;
}

const NormalizedLineGraph = ({
  graphData,
  universeValue,
}: NormalizedLineGraphProps) => {
  const getFilteredData = (): any => {
    if (!graphData) return null;

    let filterData: FilterData | undefined;
    switch (universeValue) {
      case "Steady Performers":
        filterData = graphData["Filter 1 Stocks"];
        break;
      case "Nifty Beaters":
        filterData = graphData["Filter 2 Stocks"];
        break;
      case "Optimized Performers":
        filterData = graphData["Filter 3 Stocks"];
        break;
      default:
        filterData = graphData["Filter 1 Stocks"];
        break;
    }

    const dates = filterData?.["Normalized Price Over Time STOCKS"].Date || [];
    const months = dates.map((date: string) => {
      const d = new Date(date);
      return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}`;
    });

    const uniqueMonths = Array.from(new Set(months));

    const groupDataByMonth = (data: any) => {
      const groupedData: { [key: string]: number[] } = {};

      uniqueMonths.forEach((month) => {
        groupedData[month] = [];
      });

      dates.forEach((date: string, index: number) => {
        const d = new Date(date);
        const month = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}`;
        groupedData[month].push(data[index]);
      });

      return uniqueMonths.map((month) =>
        groupedData[month].length > 0 ? groupedData[month][0] : null
      );
    };

    const stocksData = Object.keys(
      filterData?.["Normalized Price Over Time STOCKS"] || {}
    )
      .filter((key) => key !== "Date")
      .map((key) => ({
        x: uniqueMonths,
        y: groupDataByMonth(
          filterData?.["Normalized Price Over Time STOCKS"][key] || []
        ),
        type: "scatter",
        mode: "lines",
        name: key,
        line: {
          color:
            key === "nifty100"
              ? "blue"
              : key === "nifty midcap 150"
              ? "green"
              : key === "nifty smallcap 250"
              ? "orange"
              : `rgba(${Math.floor(Math.random() * 255)},${Math.floor(
                  Math.random() * 255
                )},${Math.floor(Math.random() * 255)},1)`,
          dash:
            key === "nifty100" || key === "nifty midcap 150" || key === "nifty smallcap 250"
              ? "dot"
              : "solid",
        },
      }));

    const indicesData = Object.keys(
      filterData?.["Normalized Price Over Time INDICES"] || {}
    )
      .filter((key) => key !== "Date")
      .map((key) => ({
        x: uniqueMonths,
        y: groupDataByMonth(
          filterData?.["Normalized Price Over Time INDICES"][key] || []
        ),
        type: "scatter",
        mode: "lines",
        name: key,
        line: {
          color:
            key === "nifty100"
              ? "blue"
              : key === "nifty midcap 150"
              ? "green"
              : key === "nifty smallcap 250"
              ? "orange"
              : `rgba(${Math.floor(Math.random() * 255)},${Math.floor(
                  Math.random() * 255
                )},${Math.floor(Math.random() * 255)},1)`,
          dash:
            key === "nifty100" || key === "nifty midcap 150" || key === "nifty smallcap 250"
              ? "dot"
              : "solid",
        },
      }));

    return [...stocksData, ...indicesData];
  };
  const plotData = getFilteredData();

  return (
    <>
      {plotData ? (
        <Plot
          data={plotData}
          layout={{
            title: "Normalized Price Over Time",
            xaxis: { title: "Month" },
            yaxis: { title: "Normalized Price", type: "log", autorange: true,
              // tickvals: [1, 10, 100, 1000, 10000], // Set specific tick values
              // ticktext: ["1", "10", "100", "1000", "10000"], // Set the text for the ticks
             }, // Set y-axis to log scale
            legend: {
              orientation: "h",
              y: -0.2, // Position the legend further below the x-axis
            },
            margin: { l: 40, r: 40, b: 120, t: 40 }, // Adjust the bottom margin
          }}
          useResizeHandler
          style={{ width: "100%", height: "100%" }}
          config={{ displayModeBar: false }}
        />
      ) : (
        <p>No data available</p>
      )}
    </>
  );
};

export default NormalizedLineGraph;
