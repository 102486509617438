export const barsHistorical = [];
export const barsLive = [];



export async function make1DHistoricalRequest(ticker) {
    try {
        const url = new URL("https://3xmikf5038.execute-api.ap-south-1.amazonaws.com/default/fabric-fetch-fmp-data");
        url.searchParams.append("ticker", ticker);

        // Custom fetch with timeout (10s)
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 4000); // 10 seconds

        const response = await fetch(url.toString(), { signal: controller.signal });

        clearTimeout(timeoutId); // Clear timeout if response is received

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
        }

        const jsonified = await response.json();
        console.log(jsonified.message);
        return jsonified.data;
    } catch (error) {
        if (error.name === "AbortError") {
            console.error("API request timed out.");
            throw new Error("Request took too long. Please try again.");
        }
        throw new Error(`API request error: ${error.message}`);
    }
}




export async function makeLivePriceRequest(ticker) {
    try {
        const url = new URL("https://rtym5tpr06.execute-api.ap-south-1.amazonaws.com/default/fabric-get-fmp-data");        
        // Append the ticker as a query parameter
        url.searchParams.append('ticker', ticker);

        // Make the API request
        const response = await fetch(url.toString());

        // Check if the response status is OK
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
        }

        // Parse the response
        const jsonified = await response.json();
        console.log(jsonified.message);
        return jsonified.data; // Return only the data field
    } catch (error) {
        throw new Error(`API request error: ${error.message}`);
    }
}



export async function make5mHistoricalRequest(ticker) {
	try {
        
        const url = new URL("https://puevng72pk.execute-api.ap-south-1.amazonaws.com/default/FABRIC-fetch-5min-historical-data-fmp"); 
        // Append the ticker as a query parameter
        url.searchParams.append('ticker', ticker);

        // Make the API request
        const response = await fetch(url.toString());

        // Check if the response status is OK
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
        }

        // Parse the response
        const jsonified = await response.json();
        console.log(jsonified.message);
        return jsonified.data; // Return only the data field
    } catch (error) {
        throw new Error(`API request error: ${error.message}`);
    }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
