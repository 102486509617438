import { makeLivePriceRequest, barsLive } from "./helpers.js";
import { getGlobalTicker , onTickerChange} from "./setGlobalTicker.js";
// Change from constant to variable to allow updates
let ticker = getGlobalTicker();

// Subscribe to ticker changes
onTickerChange((newTicker) => {
    ticker = newTicker;
    console.log("Ticker updated in datafeed:", ticker);
    
});
const channelToSubscription = new Map();

// Web Worker implementation
const workerCode = `
  const tickerIntervals = new Map();
  const tickerLastBars = new Map();
  const POLLING_INTERVAL = 60000;
  function fetchTradeData(ticker) {
    self.makeLivePriceRequest(ticker)
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          const tradeData = data[0];
          const tradePrice = parseFloat(tradeData.tradePriceStr || tradeData.price);
          const tradeTime = tradeData.timestamp;
          self.updateBar(ticker, tradePrice, tradeTime);
        }
      })
      .catch(error => console.error('Worker error:', error));
  }

  function updateBar(ticker, price, time) {
    let lastBar = tickerLastBars.get(ticker);
    const nextBarTime = getNextBarTime(lastBar ? lastBar.time / 1000 : time);

    if (!lastBar || time * 1000 >= nextBarTime * 1000) {
      const newBar = {
        time: nextBarTime * 1000,
        open: price,
        high: price,
        low: price,
        close: price
      };
      tickerLastBars.set(ticker, newBar);
      self.postMessage({ type: 'newBar', ticker, bar: newBar });
    } else {
      const updatedBar = {
        ...lastBar,
        high: Math.max(lastBar.high, price),
        low: Math.min(lastBar.low, price),
        close: price
      };
      tickerLastBars.set(ticker, updatedBar);
      self.postMessage({ type: 'updateBar', ticker, bar: updatedBar });
    }
  }

  function getNextBarTime(timestamp) {
    return Math.floor(timestamp / 300) * 300 + 300;
  }

  self.onmessage = function(e) {
    const { type, ticker } = e.data;
    if (type === 'subscribe') {
      if (!tickerIntervals.has(ticker)) {
        const interval = setInterval(() => fetchTradeData(ticker), POLLING_INTERVAL);
        tickerIntervals.set(ticker, interval);
        fetchTradeData(ticker);
      }
    } else if (type === 'unsubscribe') {
      const interval = tickerIntervals.get(ticker);
      if (interval) {
        clearInterval(interval);
        tickerIntervals.delete(ticker);
        tickerLastBars.delete(ticker);
      }
    }
  };

  // Mock the imported function inside the worker
  self.makeLivePriceRequest = ${makeLivePriceRequest.toString()};
`;

const workerBlob = new Blob([workerCode], { type: 'application/javascript' });
const worker = new Worker(URL.createObjectURL(workerBlob));

// Handle worker messages
worker.onmessage = (e) => {
  const { type, ticker, bar } = e.data;
  const subscription = channelToSubscription.get(ticker);

  if (!subscription) return;

  if (type === 'newBar') {
    barsLive.push(bar);
  } else if (type === 'updateBar') {
    barsLive[barsLive.length - 1] = bar;
  }

  subscription.handlers.forEach(handler => handler.callback(bar));
};

// Keep these functions outside the worker
export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastBar
) {
  const channelString = ticker;
  let subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem) {
    subscriptionItem.handlers.push({ id: subscriberUID, callback: onRealtimeCallback });
  } else {
    subscriptionItem = {
      subscriberUID,
      resolution,
      lastBar,
      handlers: [{ id: subscriberUID, callback: onRealtimeCallback }]
    };
    channelToSubscription.set(channelString, subscriptionItem);
    worker.postMessage({ type: 'subscribe', ticker });
  }
}

export function unsubscribeFromStream(subscriberUID) {
  for (const [channel, sub] of channelToSubscription.entries()) {
    const index = sub.handlers.findIndex(h => h.id === subscriberUID);
    if (index !== -1) {
      sub.handlers.splice(index, 1);
      if (sub.handlers.length === 0) {
        channelToSubscription.delete(channel);
        worker.postMessage({ type: 'unsubscribe', ticker: channel });
      }
      break;
    }
  }
}