///to get tweets graph code back uncomment 'tweets removed' code


// if the stock mart closed on sat and sunday
// these graphs still get data ... cause news still there
// 1- News Mean Sentiment score vs Total News 
// 2 - Company News vs Sector News

// only Candlestick Per Day and 'Stock Volume vs Close Price' graphs show  sat sunday holiday



import React, { useEffect, useState, Suspense } from "react";
import StockSentiment from "../Graphs/StockSentiment/StockSentiment";
import CandlestickPerDay from "../Graphs/CandlestickPerDay/CandlestickPerDay";
// import NewsAndStock from "../Graphs/NewsAndStock/NewsAndStock";
import Nouislider from "nouislider-react";
import { useDispatch } from "react-redux";
import NewsList from "../../Actions/NewsList";
import TweetsList from "../../Actions/TweetsList";
import axios from "axios";
import "./technicalAnlysis.scss"; 
// import moment  from "moment";  //tweets removed
import {
  newsSentimentType,
  Stock_Candle_Stick_DataTypes,
  newsOverTimeVsTotalNewsTypes,
  stockVsCloseTypes,
  //tweets removed
  // tweetsOverTimeVsTotalTweetsTypes,
  // setTweetSentimentVsVolumeProps,
} from "./technicalAnalysis.types";
import {useParams,} from "react-router-dom";
import { getDateYYYYMMDD } from "../../utils/Common";
import StackedBarLine from "../Graphs/StackedBarLine/StackedBarLine";
import StockVolumeClose from "../Graphs/StockVolumeClose/StockVolumeClose";
import CandleStickPattern from "../CandleStickPattern/CandleStickPattern";
import { format } from "date-fns";
// const RandomChart = React.lazy(
//   () => import("../Graphs/RandomChart/RandomChart")
// );
import TradingViewChartJSX from "../../Views/Graphs/TradingViewAdvCharts/App";
import { setGlobalTicker } from '../../Views/Graphs/TradingViewAdvCharts/APIDatafeed/setGlobalTicker';
/**
 * Technical Analysis Component.
 *
 * This component is responsible for displaying various technical analysis graphs and charts
 * related to a given stock. It includes charts for news sentiment vs. volume, stock candlestick
 * data, close vs. volume, stock sentiment over time, and more.
 *
 * @returns {JSX.Element} - A React JSX element representing the Technical Analysis component.
 */

const  recursiveDate = (dated:any) => {
  if(dated.getDay() === 0 || dated.getDay() === 6){
    dated.setDate(dated.getDate() - 1); //if  date not equal to sat- sun
    recursiveDate(dated);
  }
};

const TechnicalAnalysis = () => {
  let { ticker } = useParams();
  // console.log("ticker is from ABOVE techAnalysis", ticker);

useEffect(() => {
  let ticker = window.localStorage.getItem("tickerId")
  setGlobalTicker(ticker);
  console.log("ticker is from techAnalysis", ticker);
}, [ticker]);
  const dispatch = useDispatch();

  const [
    newsSentimentVsVolume,
    setNewsSentimentVsVolume,
  ] = useState<newsSentimentType>({} as newsSentimentType);
  const [
    filterNewsSentimentVsVolume,
    setFilterNewsSentimentVsVolume,
  ] = useState<newsSentimentType>({} as newsSentimentType);

  const [stockSentimentOverTime, setStockSentimentOverTime] = useState<
    Stock_Candle_Stick_DataTypes[]
  >([] as Stock_Candle_Stick_DataTypes[]);

  const [
    filterStockSentimentOverTime,
    setfilterStockSentimentOverTime,
  ] = useState<Stock_Candle_Stick_DataTypes[]>(
    [] as Stock_Candle_Stick_DataTypes[]
  );

  const [
    newsTimeVsTotalTime,
    setNewsTimeVsTotalTime,
  ] = useState<newsOverTimeVsTotalNewsTypes>(
    {} as newsOverTimeVsTotalNewsTypes
  );

  const [
    filterNewsTimeVsTotalTime,
    setFilterNewsTimeVsTotalTime,
  ] = useState<newsOverTimeVsTotalNewsTypes>(
    {} as newsOverTimeVsTotalNewsTypes
  );

  const [stockVsClose, setStockVsClose] = useState<stockVsCloseTypes>(
    {} as stockVsCloseTypes
  );

  const [
    filterStockVsClose,
    setFilterStockVsClose,
  ] = useState<stockVsCloseTypes>({} as stockVsCloseTypes);

  const [ allCandleStickPatternData, setAllCandleStickPatternData] = useState({}  as any);
  const [ filteredCandleStickPatternData, setFilteredCandleStickPatternData] = useState({} as any);


  //tweets removed
  // const [
  //   tweetsOverTimeVsTotalTweets,
  //   setTweetsOverTimeVsTotalTweets,
  // ] = useState<tweetsOverTimeVsTotalTweetsTypes>(
  //   {} as tweetsOverTimeVsTotalTweetsTypes
  // );

  // const [
  //   filterTweetsOverTimeVsTotalTweets,
  //   setfilterTweetsOverTimeVsTotalTweets,
  // ] = useState<tweetsOverTimeVsTotalTweetsTypes>(
  //   {} as tweetsOverTimeVsTotalTweetsTypes
  // );

  // const [
  //   tweetSentimentVsVolume,
  //   setTweetSentimentVsVolume,
  // ] = useState<setTweetSentimentVsVolumeProps>(
  //   {} as setTweetSentimentVsVolumeProps
  // );

  // const [
  //   filterTweetSentimentVsVolume,
  //   setFilterTweetSentimentVsVolume,
  // ] = useState<setTweetSentimentVsVolumeProps>(
  //   {} as setTweetSentimentVsVolumeProps
  // );

  const initial = 5;

  const [bar, setBar] = useState(initial);

  const getAllGraph = () => {
    let tickerId = ticker ? ticker : window.localStorage.getItem("tickerId");
    axios.get(`${process.env.REACT_APP_API_URL}/${tickerId}/technical_analysis.json`)
      .then(
        (response) => {
          //  console.log(`TA----`, response.data);
          var result: any = [];
          for (var i = 0; i < Math.floor(bar); i++) {
            var d = new Date();
            d.setDate(d.getDate() - i);
            getDateYYYYMMDD(d);
          }

          //News Sentiment Vs Volume start
          setFilterNewsSentimentVsVolume(response.data.news_sentiment_vs_volume);

          setNewsSentimentVsVolume(
            {
              dates: [],
              neg_sent: [],
              neu_sent: [],
              news: [],
              pos_sent: [],
              sent_news_max: 0,
              sent_news_min: 0,
            }
          );
          //News Sentiment Vs Volume End


          // Stock Candle Stick Data start
          setfilterStockSentimentOverTime(
            response.data.Stock_Candle_Stick_Data
          );

          let Stock_Candle_Stick_Data =
            response.data.Stock_Candle_Stick_Data !== undefined
              ? response.data.Stock_Candle_Stick_Data.filter((candle: any) => {
                return result.includes(candle.x);
              })
              : [];
          setStockSentimentOverTime(Stock_Candle_Stick_Data);
          // Stock Candle Stick Data End

          //close vs volume start
          setFilterStockVsClose(response.data.close_vs_volume);

          setStockVsClose({
            date: [],
            close: [],
            volume: [],
            max1_close: 0,
            min1_close: 0,
          });
          //close vs volume end

          //Tweet sentiment vs volume start
          //tweets removed
          // setFilterTweetSentimentVsVolume(
          //   response.data.tweet_sentiment_vs_volume
          // );

          // let tweet_date =
          //   response.data.tweet_sentiment_vs_volume.dates !== undefined
          //     ? response.data.tweet_sentiment_vs_volume.dates.filter(
          //       (date: any) => {
          //         return result.includes(date);
          //       }
          //     )
          //     : [];
          // let tweet_neg_sent =
          //   response.data.tweet_sentiment_vs_volume.neg_sent !== undefined
          //     ? response.data.tweet_sentiment_vs_volume.neg_sent.filter(
          //       (neg: any) => {
          //         return result.includes(neg.date);
          //       }
          //     )
          //     : [];
          // let tweet_neu_sent =
          //   response.data.tweet_sentiment_vs_volume.neu_sent !== undefined
          //     ? response.data.tweet_sentiment_vs_volume.neu_sent.filter(
          //       (neu: any) => {
          //         return result.includes(neu.date);
          //       }
          //     )
          //     : [];

          // let tweets =
          //   response.data.tweet_sentiment_vs_volume.tweets !== undefined
          //     ? response.data.tweet_sentiment_vs_volume.tweets.filter(
          //       (tweets: any) => {
          //         return result.includes(tweets.date);
          //       }
          //     )
          //     : [];

          // let tweet_pos_sent =
          //   response.data.tweet_sentiment_vs_volume.pos_sent !== undefined
          //     ? response.data.tweet_sentiment_vs_volume.pos_sent.filter(
          //       (pos: any) => {
          //         return result.includes(pos.date);
          //       }
          //     )
          //     : [];

          // setTweetSentimentVsVolume({
          //   dates: tweet_date,
          //   neg_sent: tweet_neg_sent,
          //   neu_sent: tweet_neu_sent,
          //   tweets: tweets,
          //   pos_sent: tweet_pos_sent,
          //   sent_tweet_max:
          //     response.data.tweet_sentiment_vs_volume.sent_tweet_max,
          //   sent_tweet_min:
          //     response.data.tweet_sentiment_vs_volume.sent_tweet_min,
          // });
          //Tweet sentiment vs volume end

          //news_count_vs_total_news starts
          const newsTicker = response.data.news_count_vs_total_news?.news_ticker?.length > 0 ?
            response.data.news_count_vs_total_news.news_ticker.map((data: any) => ({ x: new Date(String(data.date).split(',').join('-')), y: data.news_ticker }))
            : [];

          const totalNews = response.data.news_count_vs_total_news?.total_news?.length > 0 ?
            response.data.news_count_vs_total_news.total_news.map((data: any) => ({ x: new Date(String(data.date).split(',').join('-')), y: data.total_news, }))
            : [];


          const datess = response.data?.news_count_vs_total_news.date?.length > 0 ?
            response.data.news_count_vs_total_news.date
            : [];

          setFilterNewsTimeVsTotalTime({
            date: datess,
            news_ticker: newsTicker,
            total_news: totalNews,
          });

          setNewsTimeVsTotalTime({
            date: [],
            news_ticker: [],
            total_news: [],
          });
          //news_count_vs_total_news ends



          //tweets removed 
          //tweet_count_vs_total_tweet.total_tweet starts
          // const totalTweet = response.data.tweet_count_vs_total_tweet.total_tweet?.total_tweet?.length > 0 ?
          // response.data.tweet_count_vs_total_tweet.total_tweet.total_tweet.map((data: any) => ({ x: new Date(data.date), y: data.total_tweet })) : [];

          // const tweetTicker = response.data.tweet_count_vs_total_tweet.total_tweet?.tweet_ticker?.length > 0 ?
          // response.data.tweet_count_vs_total_tweet.total_tweet.tweet_ticker.map((data: any) => ({ x: new Date(data.date), y: data.tweet_ticker })) : [];
          // tweet_count_vs_total_tweet.total_tweet ends

          //tweets removed 
          // setfilterTweetsOverTimeVsTotalTweets({
          //   date: response.data.tweet_count_vs_total_tweet.date,
          //   total_tweet: totalTweet,
          //   tweet_ticker: tweetTicker,
          // });
          // setTweetsOverTimeVsTotalTweets({
          //   date: response.data.tweet_count_vs_total_tweet.date,
          //   total_tweet: totalTweet,
          //   tweet_ticker: tweetTicker,
          // });
        },
        (error) => {
          console.log(error);
        }
      );
  };


  const getCandleStickPatternData = async () =>{

    try {

      let tickerId = ticker ? ticker : window.localStorage.getItem("tickerId");

      //JSWSTEEL.NS
      // const res:any = await axios.get(`${process.env.REACT_APP_COMPANY_DOC}/${tickerId}/candlestick-pattern.json`);
      // const res: any = await axios.get(`${process.env.REACT_APP_COMPANY_DOC}/JSWSTEEL.NS/candlestick-pattern.json`);
      const res: any = await axios.get(`${process.env.REACT_APP_CANDLESTICK_PATTERN}/${tickerId}/candlestick-pattern.json`);
      console.log('getCandleStickPatternData', res.data);
      setAllCandleStickPatternData(res.data);

    } catch (error) {
     // console.log('getCandleStickPatternData', error);
    }


  };

  useEffect(() => {
    dispatch(NewsList(null));
    dispatch(TweetsList(null));
    getAllGraph();
    getCandleStickPatternData();
  }, []);

  const update = (values: any) => {
    setBar(parseFloat(values[0]));
    // console.log(`TA-- update`, values[0])
    // setFilterDates(result);
  };




  //-------------logic for candlestick pattern starts--------------------
  useEffect(()=>{
//candlestick pattern ---> will always have prev date data

    let dated:any = new Date(); //todays date

    dated.setDate(dated.getDate() - 1); //get prev day

    recursiveDate(dated); //if prev day is sat or sunday

     //console.log("lastDate-------", dated, );

    if (Object.keys(allCandleStickPatternData || {})?.length === 0) {
      //ignore
    } else if (bar > 0) { //bar lowest value is 1 day
      const formatLastDate:any = getDateYYYYMMDD(dated); //check in object
      const lastDateToDisplay:any  = format(new Date(dated), 'dd-MM-yyyy');

      //console.log("lastDate-------", dated, formatLastDate, lastDateToDisplay);

      if (allCandleStickPatternData[formatLastDate]) { //currently using date for testing/creation use lastDate latter

        //pls format date //date format as 10-8-2023 DD-MM-YYYY
        const newData = { ...allCandleStickPatternData[formatLastDate], lastDated: lastDateToDisplay }; //add date so can display
        setFilteredCandleStickPatternData(() => newData);
      } else {
        setFilteredCandleStickPatternData(() => { });
      }
    }

  }, [bar, allCandleStickPatternData])
  //---------------logic for candlestick pattern ends----------------------------


  //filterNewsSentimentVsVolume starts
  useEffect(() => {
    var result: any = [];
    for (var i = 0; i < Math.floor(bar); i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(getDateYYYYMMDD(d));
    }

    if (bar > 0) {
      let date =
        filterNewsSentimentVsVolume.dates !== undefined
          ? filterNewsSentimentVsVolume.dates.filter((date) => {
            return result.includes(date);
          })
          : [];
      let neg_sent =
        filterNewsSentimentVsVolume.neg_sent !== undefined
          ? filterNewsSentimentVsVolume.neg_sent.filter((neg) => {
            return result.includes(neg.date);
          })
          : [];
      let neu_sent =
        filterNewsSentimentVsVolume.neu_sent !== undefined
          ? filterNewsSentimentVsVolume.neu_sent.filter((neu) => {
            return result.includes(neu.date);
          })
          : [];

      let news =
        filterNewsSentimentVsVolume.news !== undefined
          ? filterNewsSentimentVsVolume.news.filter((news) => {
            return result.includes(news.date);
          })
          : [];

      let pos_sent =
        filterNewsSentimentVsVolume.pos_sent !== undefined
          ? filterNewsSentimentVsVolume.pos_sent.filter((pos) => {
            return result.includes(pos.date);
          })
          : [];
      setNewsSentimentVsVolume({
        dates: date,
        neg_sent: neg_sent,
        neu_sent: neu_sent,
        news: news,
        pos_sent: pos_sent,
        sent_news_max: filterNewsSentimentVsVolume.sent_news_max,
        sent_news_min: filterNewsSentimentVsVolume.sent_news_min,
      });
    }
  }, [bar, filterNewsSentimentVsVolume]);
  //filterNewsSentimentVsVolume  ends


  //filterTweetSentimentVsVolume starts
  //tweets removed
  //   useEffect(() => {
  //     var result: any = [];
  //     for (var i = 0; i < Math.floor(bar); i++) {
  //       var d = new Date();
  //       d.setDate(d.getDate() - i);
  //       result.push(getDateYYYYMMDD(d));
  //     }
  // //console.log(result)
  //     if (bar > 0) {
  //       let date =
  //         filterTweetSentimentVsVolume.dates !== undefined
  //           ? filterTweetSentimentVsVolume.dates.filter((date) => {
  //             return result.includes(date);
  //           })
  //           : [];
  //       let neg_sent =
  //         filterTweetSentimentVsVolume.neg_sent !== undefined
  //           ? filterTweetSentimentVsVolume.neg_sent.filter((neg) => {
  //             return result.includes(neg.date);
  //           })
  //           : [];
  //       let neu_sent =
  //         filterTweetSentimentVsVolume.neu_sent !== undefined
  //           ? filterTweetSentimentVsVolume.neu_sent.filter((neu) => {
  //             return result.includes(neu.date);
  //           })
  //           : [];

  //       let tweets =
  //         filterTweetSentimentVsVolume.tweets !== undefined
  //           ? filterTweetSentimentVsVolume.tweets.filter((tweets) => {
  //             return result.includes(tweets.date);
  //           })
  //           : [];

  //       let pos_sent =
  //         filterTweetSentimentVsVolume.pos_sent !== undefined
  //           ? filterTweetSentimentVsVolume.pos_sent.filter((pos) => {
  //             return result.includes(pos.date);
  //           })
  //           : [];
  //       setTweetSentimentVsVolume({
  //         dates: date,
  //         neg_sent: neg_sent,
  //         neu_sent: neu_sent,
  //         tweets: tweets,
  //         pos_sent: pos_sent,
  //         sent_tweet_max: filterTweetSentimentVsVolume.sent_tweet_max,
  //         sent_tweet_min: filterTweetSentimentVsVolume.sent_tweet_min,
  //       });
  //     }
  //   }, [bar]);
  //filterTweetSentimentVsVolume ends

  // filterStockSentimentOverTime starts
  useEffect(() => {
    var result: any = [];
    for (var i = 0; i < Math.floor(bar); i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(getDateYYYYMMDD(d));
    }

    if (bar > 0) {
      let Stock_Candle_Stick_Data =
        filterStockSentimentOverTime !== undefined
          ? filterStockSentimentOverTime.filter((candle) => {
            return result.includes(candle.x);
          })
          : [];

      setStockSentimentOverTime(Stock_Candle_Stick_Data);
    }
  }, [bar, filterStockSentimentOverTime]);
  // filterStockSentimentOverTime ends

  //filterStockVsClose starts
  useEffect(() => {
    var result: any = [];
    for (var i = 0; i < Math.floor(bar); i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(getDateYYYYMMDD(d));
    }

    if (bar > 0) {
      let date =
        filterStockVsClose.date !== undefined
          ? filterStockVsClose.date.filter((date) => {
            return result.includes(date);
          })
          : [];

      let close =
        filterStockVsClose.close !== undefined
          ? filterStockVsClose.close.filter((close) => {
            return result.includes(close.date);
          })
          : [];

      let volume =
        filterStockVsClose.volume !== undefined
          ? filterStockVsClose.volume.filter((volume) => {
            return result.includes(volume.date);
          })
          : [];

      setStockVsClose({
        date: date,
        close: close,
        volume: volume,
        max1_close: filterStockVsClose.max1_close,
        min1_close: filterStockVsClose.min1_close,
      });

    }
  }, [bar, filterStockVsClose]);
  //filterStockVsClose ends

  //filterNewsTimeVsTotalTime starts
  useEffect(() => {
    var result: any = [];
    for (var i = 0; i < Math.floor(bar); i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(getDateYYYYMMDD(d));
    }

    if (bar > 0) {
      const datesArrFiltered = filterNewsTimeVsTotalTime.date?.length > 0 ?  //2023-05-22
        filterNewsTimeVsTotalTime.date
          .filter(dated => result.includes(String(getDateYYYYMMDD(dated.split(',').join('-')))))
        : [];

      const newsTickerFiltered = filterNewsTimeVsTotalTime.news_ticker?.length > 0 ?
        filterNewsTimeVsTotalTime.news_ticker.filter(dated => result.includes(String(getDateYYYYMMDD(dated?.x))))
        : [];

      const totalNewsFiltered = filterNewsTimeVsTotalTime.total_news?.length > 0 ?
        filterNewsTimeVsTotalTime.total_news.filter(dated => result.includes(String(getDateYYYYMMDD(dated?.x))))
        : [];

      setNewsTimeVsTotalTime({
        date: datesArrFiltered,
        news_ticker: newsTickerFiltered,
        total_news: totalNewsFiltered,
      });

    }
  }, [bar, filterNewsTimeVsTotalTime]);
  //filterNewsTimeVsTotalTime ends


  //filterTweetsOverTimeVsTotalTweets starts
  //tweets removed 
  // useEffect(() => {
  //   var result: any = [];
  //   for (var i = 0; i < Math.floor(bar); i++) {
  //     var d = new Date();
  //     d.setDate(d.getDate() - i);
  //     result.push(moment(d).format("YYYY-MM-DD"));
  //   }

  //   if (bar > 0) {
  //     let date =
  //       filterTweetsOverTimeVsTotalTweets.date !== undefined
  //         ? filterTweetsOverTimeVsTotalTweets.date.filter((date) => {
  //           return result.includes(date);
  //         })
  //         : [];
  //     let tweet_ticker =
  //       filterTweetsOverTimeVsTotalTweets.tweet_ticker !== undefined
  //         ? filterTweetsOverTimeVsTotalTweets.tweet_ticker.filter(
  //           (tweet_ticker) => {
  //             return result.includes(
  //               moment(tweet_ticker.x).format("YYYY-MM-DD")
  //             );
  //           }
  //         )
  //         : [];

  //     let total_tweet =
  //       filterTweetsOverTimeVsTotalTweets.total_tweet !== undefined
  //         ? filterTweetsOverTimeVsTotalTweets.total_tweet.filter(
  //           (total_tweet) => {
  //             return result.includes(
  //               moment(total_tweet.x).format("YYYY-MM-DD")
  //             );
  //           }
  //         )
  //         : [];

  //     setTweetsOverTimeVsTotalTweets({
  //       date: date,
  //       total_tweet: total_tweet,
  //       tweet_ticker: tweet_ticker,
  //     });
  //   }
  // }, [filterTweetsOverTimeVsTotalTweets]);

  //tweets removed 
  // useEffect(() => {
  //   var result: any = [];
  //   for (var i = 0; i < Math.floor(bar); i++) {
  //     var d = new Date();
  //     d.setDate(d.getDate() - i);
  //     result.push(moment(d).format("YYYY-MM-DD"));
  //   }

  //   if (bar > 0) {
  //     let date =
  //       filterTweetsOverTimeVsTotalTweets.date !== undefined
  //         ? filterTweetsOverTimeVsTotalTweets.date.filter((date) => {
  //           return result.includes(date);
  //         })
  //         : [];
  //     let tweet_ticker =
  //       filterTweetsOverTimeVsTotalTweets.tweet_ticker !== undefined
  //         ? filterTweetsOverTimeVsTotalTweets.tweet_ticker.filter(
  //           (tweet_ticker) => {
  //             return result.includes(
  //               moment(tweet_ticker.x).format("YYYY-MM-DD")
  //             );
  //           }
  //         )
  //         : [];

  //     let total_tweet =
  //       filterTweetsOverTimeVsTotalTweets.total_tweet !== undefined
  //         ? filterTweetsOverTimeVsTotalTweets.total_tweet.filter(
  //           (total_tweet) => {
  //             return result.includes(
  //               moment(total_tweet.x).format("YYYY-MM-DD")
  //             );
  //           }
  //         )
  //         : [];

  //     setTweetsOverTimeVsTotalTweets({
  //       date: date,
  //       total_tweet: total_tweet,
  //       tweet_ticker: tweet_ticker,
  //     });
  //   }
  // }, [bar]);

  //filterTweetsOverTimeVsTotalTweets ends



  return (
    
    <div className="technical-analysis">
      <div className=" text-center bar-title">
        <span >Days</span>
        <Nouislider
          style={{ width: "100%", margin: "0rem 2rem 0 1rem" }}
          behaviour="tap-snap"
          connect={[true, false]}
          start={initial}
          onUpdate={update}
          range={{ min: [1], max: [30] }}
        />
        <div className="mint-text text-center ">{`${Math.floor(bar)}`}</div>
      </div>
      {/* <div className="row row-flex"> */}
      <div className="row">
        <StackedBarLine data={newsSentimentVsVolume} key="NewsSentiment" />
        {/* <NewsAndStock tweetSentimentVsVolume={tweetSentimentVsVolume} /> */}
        <CandlestickPerDay stockSentimentOverTime={stockSentimentOverTime} />
        <CandleStickPattern data={filteredCandleStickPatternData}/>
      </div>
      <div className="trading-view-chart">
      <TradingViewChartJSX/>
      </div>
      <div className="row row-flex">
        <StockSentiment newsTimeVsTotalTime={newsTimeVsTotalTime} />
        {/*<Suspense fallback="">
           <RandomChart
            tweetsOverTimeVsTotalTweets={tweetsOverTimeVsTotalTweets}
          /> 
        </Suspense>*/}
        <StockVolumeClose data={stockVsClose} />
      </div>
    </div>
  );
};

export default React.memo(TechnicalAnalysis);
