import { useContext, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";

import "./Navbar.scss";
// import NewsletterDialog from "./NewsletterSection/NewsletterDialog";
import { AuthContext } from "../../context/AuthContext";

const Navbar = () => {
  // Get current location
  let location = useLocation();

  const [mobileToggle, setMobileToggle] = useState<boolean>(false);
  // const dialogRef = useRef<HTMLDialogElement>(null);

  const { userInfo, setUserInfo } = useContext(AuthContext);

  const userLinks =
    userInfo == null
      ? [
          { name: "Login", href: "/login" },
          { name: "Register", href: "/register" },
        ]
      : [{ name: "Logout", href: "/" }];

  const links = [
    { name: "Home", href: "/" },
    { name: "What’s trending?", href: "/trending" },
    { name: "Investment Ideas", href: "/nifty-statergy" },
    { name: "Dashboard", href: "/dashboard" },
    { name: "Semantic Search ", href: "/search" },
    {
      name: "News Aggregator",
      href: "/aggregator",
    },
  ];

  const allLinks = [...userLinks, ...links];

  // console.log(new RegExp("/strong-stocks$").test(location.pathname));

  // const logout = () => {
  //   setUserInfo(null);
  // };

  const logout = async () => {
    try {
      // Call logout backend functionality to remove cookies from storage
      await fetch(
        `${process.env.REACT_APP_AUTH_URL || "http://localhost:3000"}/logout`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      setUserInfo(null);
    } catch (error) {
      console.error("Logout failed:", error);
    }
    //redirect page to home page when user logout
    window.location.href = "/";
  };

  return (
    <nav className="landingv2-nav">
      <div className="nav-flex">
        {/* Main logo */}
        <div className="main-logo">
          <Link to="/">
            <img src="/assets/images/landingv2/main-logo.png" alt="Main Logo" />
          </Link>
        </div>
        {/* Links */}
        <ul className={`links ${!mobileToggle && "links-hide-mobile"}`}>
          {allLinks.map((link, index) => {
            const isActive = new RegExp(link.href + "$").test(
              location.pathname
            );
            const isMobileOnly =
              link.name === "Login" ||
              link.name === "Register" ||
              link.name === "Logout";
            return (
              <li
                key={index}
                className={`${`${
                  isActive &&
                  link.name !== "Login" &&
                  link.name !== "Register" &&
                  link.name !== "Logout" &&
                  "active"
                }
               ${isMobileOnly ? "mobile-only" : ""}`}`}
              >
                {link.href[0] === "#" ? (
                  <a href={link.href}>{link.name}</a>
                ) : (
                  <Link
                    to={link.href}
                    onClick={link.name === "Logout" ? logout : undefined}
                  >
                    {link.name}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
        {/* Buttons */}
        <div className="buttons">
          {/* <a href="#newletter-section">
            <button onClick={() => dialogRef.current?.showModal()}>
              Subscribe
            </button>
          </a> */}
          {userInfo == null ? (
            <>
              <a href="/login">
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 27.2333V27.1667C8 25.7522 8.5619 24.3956 9.5621 23.3954C10.5623 22.3952 11.9188 21.8333 13.3333 21.8333H18.6667C20.0812 21.8333 21.4377 22.3952 22.4379 23.3954C23.4381 24.3956 24 25.7522 24 27.1667V27.2333M16 17.8333C17.0609 17.8333 18.0783 17.4119 18.8284 16.6618C19.5786 15.9116 20 14.8942 20 13.8333C20 12.7725 19.5786 11.7551 18.8284 11.0049C18.0783 10.2548 17.0609 9.83333 16 9.83333C14.9391 9.83333 13.9217 10.2548 13.1716 11.0049C12.4214 11.7551 12 12.7725 12 13.8333C12 14.8942 12.4214 15.9116 13.1716 16.6618C13.9217 17.4119 14.9391 17.8333 16 17.8333ZM16 4.5C25.6 4.5 28 6.9 28 16.5C28 26.1 25.6 28.5 16 28.5C6.4 28.5 4 26.1 4 16.5C4 6.9 6.4 4.5 16 4.5Z"
                    stroke="#2D4890"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <a href="/register">
                <button>Register</button>
              </a>
            </>
          ) : (
            <button onClick={logout}>Logout</button>
          )}
          {/* when userInfo email = test1@dev.com and password =123456789 show the button monitoring dashboard */}
          {userInfo?.email === "test1@dev.com" ? (
            <div id="monitoring-dashboard">
              <a
                href="https://algofabricmonitoringdashboard.streamlit.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Monitoring Dashboard</Button>
              </a>
            </div>
          ) : null}
        </div>
        {/* Mobile Hamburger */}
        <button
          className="mobile-button"
          onClick={() => setMobileToggle((prev) => !prev)}
        >
          <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6667 4.16675C17.1269 4.16675 17.5 4.53984 17.5 5.00008C17.5 5.46032 17.1269 5.83341 16.6667 5.83341H3.33333C2.8731 5.83341 2.5 5.46032 2.5 5.00008C2.5 4.53984 2.8731 4.16675 3.33333 4.16675H16.6667ZM16.6667 9.16675C17.1269 9.16675 17.5 9.53984 17.5 10.0001C17.5 10.4603 17.1269 10.8334 16.6667 10.8334H3.33333C2.8731 10.8334 2.5 10.4603 2.5 10.0001C2.5 9.53984 2.8731 9.16675 3.33333 9.16675H16.6667ZM16.6667 14.1667C17.1269 14.1667 17.5 14.5398 17.5 15.0001C17.5 15.4603 17.1269 15.8334 16.6667 15.8334H3.33333C2.8731 15.8334 2.5 15.4603 2.5 15.0001C2.5 14.5398 2.8731 14.1667 3.33333 14.1667H16.6667Z"
              fill="#2D4890"
            />
          </svg>
        </button>
      </div>
      {/* <NewsletterDialog dialogRef={dialogRef} /> */}
    </nav>
  );
};

export default Navbar;
