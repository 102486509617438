import Navbar from "../../Component/LandingV2/Navbar";
import SubNavbar from "../../Component/LandingV2/SubNavbar";
import { useEffect, useState } from "react";
import "../NewStatergy/NewStatergy.scss";
import Plot from "react-plotly.js";
import Papa from "papaparse";
import FilterSelect from "../../Component/StockSelection/FilterSelect";
import BacktestingTableV2 from "../NewStatergy/BacktestingTableV2";
import BacktestingGraphV2 from "../NewStatergy/BacktestingGraphV2";
import GraphDataDummy from "../../data/stock-selection/Apr-2015_v2.json";
import NormalisedGraphV2 from "../NewStatergy/NormalisedGraphV2";
import HeatMap from "./HeatMap";
import HeatMap2 from "./HeatMap2";

type HistoricalData = {
  "NIFTY SMALLCAP 100": StockParameters;
  "NIFTY 100": StockParameters;
  "NIFTY TMI": StockParameters;
  NIFTYBEES: StockParameters;
  "Our Strategy": StockParameters;
  [key: string]: StockParameters;
};

interface SubNavItem {
  name: string;
  href: string;
  dropdown?: SubNavItem[];
}

interface PlotData {
  graphData: {
    Date: string[];
    "Final Investment": number[];
  };
  NIFTYBEES: {
    Date: string[];
    "Final Investment": number[];
  };
}

export type PerformanceData = {
  Date: string[];
  Investment: number[];
};

export type NormalizedPrices = {
  Date: string[];
  [key: string]: number[] | string[];
};

export type StockParameters = {
  "Initial Capital": number;
  "Final Capital": number;
  "Annualized Returns": number;
  "Calmar Ratio": number;
  "Sharpe Ratio": number;
  "Sortino Ratio": number;
  "Max Drawdown": number;
  [key: string]: number;
};
export type FilterData = {
  Stocks: string[];
  "Backtesting Performance Our Stock": PerformanceData;
  "Backtesting Performance NIFTYBEES": PerformanceData;
  "Our Stocks Parameters"?: StockParameters;
  "NIFTYBEES Parameters"?: StockParameters;
  "Normalized Price Over Time STOCKS": NormalizedPrices;
  "Normalized Price Over Time INDICES": NormalizedPrices;
};

export type valueGraphData = {
  "Filter 3": FilterData;
};
const subNavData: SubNavItem[] = [
  {
    name: "Nifty Strategy",
    href: "/nifty-statergy",
  },
  {
    name: "Momentum Strategy",
    href: "/momentum-strategy",
  },
  {
    name: "Value Strategy",
    href: "/value-statergy",
  },
];

const ValueStatergy = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const years = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
  ];
  const getFutureMonths = (currentYear: number, selectedYear: string) => {
    const currentMonth = new Date().getMonth(); // 0 (Jan) to 11 (Dec)
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (parseInt(selectedYear) === currentYear) {
      return months.slice(currentMonth + 1);
    }
    return [];
  };
  const currentYear = new Date().getFullYear();
  const getLastMonth = () => {
    const now = new Date();
    const lastMonthIndex = now.getMonth(); // getMonth() returns 0 for Jan, 1 for Feb, etc.
    return months[lastMonthIndex < 0 ? 11 : lastMonthIndex]; // If last month is negative, wrap around to December
  };
  const [monthValue, setMonthValue] = useState(getLastMonth());
  const [yearValue, setYearValue] = useState(currentYear.toString());
  const [historicalData, setHistoricalData] = useState<HistoricalData | null>(
    null
  );
  const [graphData, setGraphData] = useState<valueGraphData | null>(
    GraphDataDummy
  );
  const [plotData, setPlotData] = useState<PlotData | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [valueBasedData, setValueBasedData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const todayDate = new Date().toISOString().split("T")[0];

  // State to track column visibility for ROE and ROCE
  const [isROEExpanded, setIsROEExpanded] = useState(false);
  const [isROCEExpanded, setIsROCEExpanded] = useState(false);
  const [isAvgPriceRankExpanded, setIsAvgPriceRankExpanded] = useState(false);
  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);
  const [reComputeLoading, setReComputeLoading] = useState(false);

  // Function to toggle visibility of ROE columns
  const toggleROEColumns = () => {
    setIsROEExpanded((prev) => !prev);
  };

  // Function to toggle visibility of ROCE columns
  const toggleROCEColumns = () => {
    setIsROCEExpanded((prev) => !prev);
  };

  // Function to toggle visibility of Avg Price Rank columns
  const toggleAvgPriceRankColumns = () => {
    setIsAvgPriceRankExpanded((prev) => !prev);
  };

  const stockOrder = {
    green: 1,
    blue: 2,
    yellow: 3,
    red: 4,
  };

  const orderedKeys = [
    "NIFTY SMALLCAP 100",
    "NIFTY 100",
    "NIFTY TMI",
    "NIFTYBEES",
    "Our Strategy",
  ];

  function getYesterdayDate() {
    const date = new Date();
    date.setDate(date.getDate() - 1); // Set date to yesterday

    const day = date.getDate(); // Day of the month
    const year = date.getFullYear(); // Year

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[date.getMonth()]; // Get month name

    return `${day} ${month} ${year}`;
  }

  const fetchValueBasedData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/analysis-fabric-files/Value Based Strategy/${encodeURI(
            monthValue
          )}+${encodeURI(yearValue)}.json`
      );
      const data = await response.json();
      // console.log(data);
      setGraphData(data);
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_COMPANY_DOC}/Stock-Selection/stock-selection-by-value/daily_stock_list/${todayDate}.csv`
      );
      const text = await response.text();
      const csvData = csvToJson(text);
      // console.log(csvData);
      const sortedData = sortValueBasedStocks(csvData);
      setValueBasedData(sortedData);
      setData(sortedData);
      // Add show checkbox property to green stocks
      const filteredData = sortedData.map((item) => ({
        ...item,
        showCheckBox:
          item.Meets_ROCE_ROE_Condition === "True" &&
          item.Meets_Price_Ratio_Condition === "True" &&
          item.Final_Selected === "1"
            ? "1"
            : "0",
      }));
      setValueBasedData(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  const sortValueBasedStocks = (stocks: any[]) => {
    return stocks.sort((a, b) => {
      const aClass = getValueStockClass(a);
      const bClass = getValueStockClass(b);
      return stockOrder[aClass] - stockOrder[bClass];
    });
  };

  const getValueStockClass = (item: any) => {
    if (item.F3_Max_Sharpe === "True") {
      return "green";
    } else if (item.F2_Price_Ranking === "True") {
      return "blue";
    } else if (item.F1_Momentum === "True") {
      return "yellow";
    } else {
      return "red";
    }
  };

  const fetchPlotData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_COMPANY_DOC}/Stock-Selection/backtest_results_daily/Value Based Strategy/plots_data.json`
      );
      const data = await response.json();
      // console.log(data);
      setPlotData({
        graphData: data["Value Based Strategy"],
        NIFTYBEES: data["NIFTYBEES"],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHistoricalData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/backtest_results_daily/Value Based Strategy/table_data.json`
      );
      const data = await response.json();
      // console.log(data);
      setHistoricalData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const csvToJson = (csv: string) => {
    const parsedData = Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
    }).data;

    return parsedData.map((row: any) => {
      const parsedRow: any = {};
      for (const key in row) {
        const value = row[key]?.trim();

        // Parse boolean values
        if (value === "TRUE" || value === "FALSE") {
          parsedRow[key] = value === "True";
        }
        // Parse numbers (integers or floats)
        else if (!isNaN(value) && value !== "") {
          parsedRow[key] = parseFloat(value);
        }
        // Parse arrays
        else if (value.startsWith("[") && value.endsWith("]")) {
          parsedRow[key] = value
            .slice(1, -1) // Remove square brackets
            .split("  ") // Split by comma
            .map((item: string) => item.trim().replace(/^["]|["]$/g, " ")); // Trim and remove quotes
        } else {
          // Leave the value as a string if it doesn't match any condition
          parsedRow[key] = value;
        }
      }
      // console.log(parsedRow);
      return parsedRow;
    });
  };

  useEffect(() => {
    fetchValueBasedData();
    fetchPlotData();
    fetchHistoricalData();
  }, [monthValue, yearValue]);

  // Sorting logic
  const handleSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    const sortedData = [...valueBasedData].sort((a, b) => {
      if (key === "Stock") {
        return direction === "ascending"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === "ascending"
          ? parseFloat(a[key]) - parseFloat(b[key])
          : parseFloat(b[key]) - parseFloat(a[key]);
      }
    });
    setSortConfig({ key, direction });
    setValueBasedData(sortedData);
  };

  // Determine sort icon based on the current sort configuration
  const getSortIcon = (key: string) => {
    if (!sortConfig || sortConfig.key !== key) {
      return ""; // No sort icon
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };
  const handleFilterValueBased = (colorClass: string) => {
    const filtered = data.filter((item) => {
      if (colorClass === "green") {
        const isMaxSharpe = item.F3_Max_Sharpe === "True";
        // When clicking Max Sharpe legend, select/deselect all Max Sharpe stocks
        if (isMaxSharpe) {
          const maxSharpeStocks = data
            .filter((item) => item.F3_Max_Sharpe === "True")
            .map((item) => item.Ticker);

          if (selectedStocks.length === maxSharpeStocks.length) {
            setSelectedStocks([]); // Deselect all if all were selected
          } else {
            setSelectedStocks(maxSharpeStocks); // Select all Max Sharpe stocks
          }
        }
        return isMaxSharpe;
      } else if (colorClass === "blue") {
        return item.F2_Price_Ranking === "True";
      } else if (colorClass === "yellow") {
        return item.F1_Momentum === "True";
      } else {
        return true;
      }
    });
    // Show checkbox if colorClass is green
    const filteredWithCheckbox = filtered.map((i) => ({
      ...i,
      showCheckBox: colorClass === "green" ? "1" : "0",
    }));
    setValueBasedData(sortValueBasedStocks(filteredWithCheckbox));
  };

  const handleRecompute = async () => {
    try {
      setReComputeLoading(true);
      const delist = valueBasedData
        .filter((item) => !selectedStocks.includes(item.Ticker))
        .map((item) => item.Ticker);
      const original = data
        .filter(
          (item) =>
            item.F3_Max_Sharpe === "True" || item.F2_Price_Ranking === "True"
        )
        .map((item) => item.Ticker);
      const body = {
        body: {
          delist: delist,
          original: original,
          num_stocks: 6,
        },
      };
      console.log(body);
      const response = await fetch(
        "https://l5gp8ygp78.execute-api.ap-south-1.amazonaws.com/Prod/test",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const newData = await response.json();
      console.log(newData.body);
      const newFilteredData = data
        .filter((item) => newData.body.includes(item.Ticker))
        .map((i) => ({ ...i, showCheckBox: "1" }));
      setValueBasedData(newFilteredData);
      setSelectedStocks(newFilteredData.map((i) => i.Ticker));
    } catch (error) {
      console.error("Failed to recompute stocks:", error);
    } finally {
      setReComputeLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <SubNavbar navData={subNavData} />
      <div className="new-value-statergy-container">
        <div className="recompute-section">
          <button
            className="recompute-button"
            onClick={handleRecompute}
            disabled={selectedStocks.length === 0}
          >
            ReCompute
          </button>
        </div>
        {/* Legend Section */}
        <div className="color-legend">
          <div className="main-legend">
            <p onClick={() => handleFilterValueBased("green")}>
              <span className="legend-box green"></span> Max Sharpe Optimised
              Stocks
            </p>
            <p onClick={() => handleFilterValueBased("blue")}>
              <span className="legend-box blue"></span> Price Ranking Stocks
            </p>
            <p onClick={() => handleFilterValueBased("yellow")}>
              <span className="legend-box yellow"></span> Momentum Stocks
            </p>
            <p onClick={() => handleFilterValueBased("red")}>
              <span className="legend-box red"></span> Value Based Stocks
            </p>
          </div>
          <div className="update-line">Stocks Updated on {todayDate}</div>
          {/* <p onClick={() => setFilteredData(data)}>Show All</p> Button to reset filter */}
        </div>
        <div className="table-momentum-strategy">
          <table className="table">
            <thead>
              <tr>
                <th onClick={() => handleSort("Ticker")}>
                  Stock {getSortIcon("Ticker")}
                </th>
                <th
                  onClick={toggleROEColumns}
                  style={{ cursor: "pointer", width: "15%" }}
                >
                  ROE Mar-24 {isROEExpanded ? "↑" : "↓"}
                </th>
                {isROEExpanded && (
                  <>
                    <th>ROE Mar-23</th>
                    <th>ROE Mar-22</th>
                    <th>ROE Mar-21</th>
                    <th>ROE Mar-20</th>
                  </>
                )}
                <th
                  onClick={toggleROCEColumns}
                  style={{ cursor: "pointer", width: "15%" }}
                >
                  ROCE Mar-24 {isROCEExpanded ? "↑" : "↓"}
                </th>
                {isROCEExpanded && (
                  <>
                    <th>ROCE Mar-23</th>
                    <th>ROCE Mar-22</th>
                    <th>ROCE Mar-21</th>
                    <th>ROCE Mar-20</th>
                  </>
                )}
                {/* <th>ROE ROCE greater than 15</th> */}
                <th>Indexes Satisfied</th>
                <th>Beta</th>
                <th>ShM</th>
                {/* <th>Momentum</th> */}
                <th
                  onClick={() => {
                    toggleAvgPriceRankColumns();
                  }}
                  style={{ cursor: "pointer", width: "15%" }}
                >
                  Avg Price Rank {isAvgPriceRankExpanded ? "↑" : "↓"}
                </th>
                {isAvgPriceRankExpanded && (
                  <>
                    <th onClick={() => handleSort("PS_Rank")}>
                      PS Rank {getSortIcon("PS_Rank")}
                    </th>
                    <th onClick={() => handleSort("PE_Rank")}>
                      PE Rank {getSortIcon("PE_Rank")}
                    </th>
                    <th onClick={() => handleSort("PB_Rank")}>
                      PB Rank {getSortIcon("PB_Rank")}
                    </th>
                  </>
                )}
                {/* <th>F2 Price Ranking</th>
              <th>F3 Max Sharpe</th> */}
                <th onClick={() => handleSort("5 Day Avg Volume (Cr)")}>
                  5 Day Avg Volume (Cr) {getSortIcon("5 Day Avg Volume (Cr)")}
                </th>
                <th>Industry</th>
                <th>Sector</th>
              </tr>
            </thead>
            <tbody>
              {valueBasedData.length !== 0 ? (
                valueBasedData.map((item, index) => {
                  // Conditional class logic
                  let rowClass = "";
                  if (item.F3_Max_Sharpe === "True") {
                    rowClass = "green-row";
                  } else if (item.F2_Price_Ranking === "True") {
                    rowClass = "blue-row";
                  } else if (item.F1_Momentum === "True") {
                    rowClass = "yellow-row";
                  } else {
                    rowClass = "red-row";
                  }
                  return (
                    <tr className={rowClass} key={index}>
                      <td style={{ textAlign: "left", width: "200px" }}>
                        {item.showCheckBox === "1" && (
                          <input
                            style={{ marginRight: "1rem" }}
                            type="checkbox"
                            checked={selectedStocks.includes(item.Ticker)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedStocks([
                                  ...selectedStocks,
                                  item.Ticker,
                                ]);
                              } else {
                                setSelectedStocks(
                                  selectedStocks.filter(
                                    (stock) => stock !== item.Ticker
                                  )
                                );
                              }
                            }}
                            title="Click to deselect this stock"
                          />
                        )}
                        {item.Ticker}
                      </td>
                      <td className="non-hover-columns">
                        {item["ROE_Mar 24"]}
                      </td>
                      {isROEExpanded && (
                        <>
                          <td>{item["ROE_Mar 23"]}</td>
                          <td>{item["ROE_Mar 22"]}</td>
                          <td>{item["ROE_Mar 21"]}</td>
                          <td>{item["ROE_Mar 20"]}</td>
                        </>
                      )}
                      <td className="non-hover-columns">
                        {item["ROCE_Mar 24"]}
                      </td>
                      {isROCEExpanded && (
                        <>
                          <td>{item["ROCE_Mar 23"]}</td>
                          <td>{item["ROCE_Mar 22"]}</td>
                          <td>{item["ROCE_Mar 21"]}</td>
                          <td>{item["ROCE_Mar 20"]}</td>
                        </>
                      )}
                      {/* <td>{item.ROE_ROCE_greater_than_15}</td> */}
                      <td className="hover-columns">
                        {item["Indexes Satisfied"]}
                      </td>
                      <td className="hover-columns">{item["Beta values"]}</td>
                      <td className="hover-columns">{item["ShM values"]}</td>
                      {/* <td>{item.F1_Momentum}</td> */}
                      {/* Show NaN values as empty cells */}
                      <td>
                        {isNaN(parseFloat(item.Avg_Price_Rank))
                          ? "NA"
                          : parseFloat(item.Avg_Price_Rank).toFixed(2)}
                      </td>
                      {isAvgPriceRankExpanded && (
                        <>
                          <td>
                            {isNaN(parseFloat(item.PS_Rank))
                              ? "NA"
                              : parseFloat(item.PS_Rank).toFixed(2)}
                          </td>
                          <td>
                            {isNaN(parseFloat(item.PE_Rank))
                              ? "NA"
                              : parseFloat(item.PE_Rank).toFixed(2)}
                          </td>
                          <td>
                            {isNaN(parseFloat(item.PB_Rank))
                              ? "NA"
                              : parseFloat(item.PB_Rank).toFixed(2)}
                          </td>
                        </>
                      )}
                      <td>
                        {parseFloat(item["5 Day Avg Volume (Cr)"]).toFixed(2)}
                      </td>
                      <td>{item.Industry}</td>
                      <td>{item.Sector}</td>
                      {/* <td>{item.F2_Price_Ranking}</td>
                    <td>{item.F3_Max_Sharpe}</td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={22}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* loading overlay */}
          {reComputeLoading && (
            <div className="loading-overlay">
              <div className="loading-spinner">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="info-line">
        *Our Strategy suggests to hold these stocks for next one year and then
        liquidate it
      </div>
      <div>
        <h1 style={{ textAlign: "center", margin: "1rem" }}>
          How Our Strategy Works?
        </h1>
        <div className="plot-table-container">
          <div className="chart-img">
            <h5 style={{ marginBottom: "1rem" }}>
              On the first of every month:
            </h5>
            <img
              className="img-strategy"
              src="assets/images/momentum-value/value-chart.png"
              alt="Momentum Value Chart"
            />
          </div>
          <div>
            <h4 style={{ textAlign: "center", margin: "1rem" }}>
              Performance of strategy vs NiftyBees over last 10 years
            </h4>
            <div className="momentum-value-plot">
              {plotData && (
                <Plot
                  data={[
                    {
                      x: plotData.graphData.Date,
                      y: plotData.graphData["Final Investment"],
                      type: "scatter",
                      mode: "lines",
                      name: "Value Based Strategy",
                      line: { color: "red" },
                    },
                    {
                      x: plotData["NIFTYBEES"].Date,
                      y: plotData["NIFTYBEES"]["Final Investment"],
                      type: "scatter",
                      mode: "lines",
                      name: "NIFTYBEES",
                      line: { color: "blue" },
                    },
                  ]}
                  layout={{
                    title: `Value Based Strategy vs NIFTYBEES`,
                    xaxis: {
                      title: "Date",
                    },
                    yaxis: {
                      title: "Final Investment",
                    },
                    legend: {
                      x: 0.5,
                      y: 1, // Align it to the top
                      orientation: "v", // Vertical orientation
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="subtext">
        <h1>
          Let's Evaluate the Effectiveness of this trading strategy by running
          it against historical data of more than 10 years
        </h1>
      </div>
      <div className="heatmap">
        <div className="title">Momentum Strategy VS NIFTYBEES.NS</div>
        <div
          style={{
            width: "70%",
            margin: "0 auto",
          }}
        >
          <HeatMap />
        </div>
      </div>
      <div className="heatmap">
        <div className="title">Strategy Returns</div>
        <div
          style={{
            width: "70%",
            margin: "0 auto",
          }}
        >
          <HeatMap2 />
        </div>
      </div>
      <div className="portfolio-section">
        <h1>Portfolio Historical Performance Review</h1>
        <div className="text-image-split">
          {/* Historical Table */}
          <div className="historical-performance-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Parameter</th>
                  {orderedKeys.map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {historicalData &&
                  Object.keys(historicalData["NIFTY SMALLCAP 100"]).map(
                    (key) => (
                      <tr key={key}>
                        <td>{key}</td>
                        <td>
                          {historicalData?.["NIFTY SMALLCAP 100"][key].toFixed(
                            2
                          )}
                        </td>
                        <td>{historicalData?.["NIFTY 100"][key].toFixed(2)}</td>
                        <td>
                          {historicalData?.["NIFTY TOTAL MARKET"][key].toFixed(
                            2
                          )}
                        </td>
                        <td>{historicalData?.["NIFTYBEES"][key].toFixed(2)}</td>
                        <td>
                          {historicalData?.["Value Based Strategy"][
                            key
                          ].toFixed(2)}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            <div className="info-line">
              *The above table shows performance from 1 July, 2014 to{" "}
              {getYesterdayDate()}
            </div>
          </div>
        </div>
      </div>
      <div className="analysis">
        <div className="title">
          Explore Historical Performance on Your Terms
        </div>
        <div className="analysis-filters">
          <FilterSelect
            options={months}
            value={monthValue}
            onChange={setMonthValue}
            placeholder="Select Month"
            disabledOptions={getFutureMonths(currentYear, yearValue)}
          />

          <FilterSelect
            options={years}
            value={yearValue}
            onChange={setYearValue}
            placeholder="Select Year"
          />
        </div>
        <div className="line-graph">
          <div style={{ alignSelf: "center" }} className="month-title">
            {monthValue} {yearValue} Stocks along with their Normalized Price
            Over Time
          </div>

          <div className="normalised-graph">
            <NormalisedGraphV2 valueGraphData={graphData} />
            <div className="info-line">
              *The chart shows the performance of selected stocks over 5 years
              before and upto 1 year after selection.
            </div>
          </div>
        </div>
        <div className="backtesting">
          <div className="forwardTesting-title">
            Forward Testing Performance {monthValue} {yearValue} stocks
          </div>
          <div className="table-graph-forward-testing">
            <div className="data-table-forward-testing">
              <BacktestingTableV2 valueGraphData={graphData} />
            </div>
            <div className="graph-forward-testing">
              <BacktestingGraphV2 valueGraphData={graphData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValueStatergy;
